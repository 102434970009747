import Vue from 'vue'
import Vuex from 'vuex'
import { cloudApiBaseUrl, cloudApiIframeKey, cloudApiReferer } from '@/constants'
import analytics from '@/stats'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentSlide: 0,
    collectedData: {
      floor: 1,
      type: ''
    },
    personalData: {},
    showLoader: false,
    leadResponse: {},
    twilioSId: null,
    smsVerified: false,
    isTransitioning: false,
    extraSteps: []
  },
  mutations: {
    nextSlide (state) {
      const oldSlide = state.currentSlide
      console.log('nextSlide mutation called. Current slide:', oldSlide)
      state.currentSlide++
      console.log('New slide index:', state.currentSlide)
      // Überprüfen Sie, ob die Änderung korrekt ist
      if (state.currentSlide !== oldSlide + 1) {
        console.warn('Unexpected slide index after increment')
      }
      document.activeElement.blur()
    },
    setTransitioning (state, isTransitioning) {
      console.log('Setting transition state to:', isTransitioning)
      state.isTransitioning = isTransitioning
    },
    prevSlide (state) {
      state.currentSlide--
      document.activeElement.blur()
    },
    gotoSlide (state, slideIndex) {
      state.currentSlide = slideIndex
      document.activeElement.blur()
    },
    updateCollectedData (state, data) {
      console.log('Store - Updating collected data:', data)
      state.collectedData = { ...state.collectedData, ...data }
      console.log('Store - New collected data:', state.collectedData)
    },
    updatePersonalData (state, newPersonalData) {
      state.personalData = { ...state.personalData, ...newPersonalData }
    },
    setExtraSteps (state, newData) {
      state.extraSteps = [...newData]
    },
    showLoader (state, show) {
      state.showLoader = show
    },
    replaceLeadResponse (state, newLeadResponse) {
      state.leadResponse = { ...newLeadResponse }
    },
    setTwilioSId (state, sId) {
      state.twilioSId = sId
    }
  },
  actions: {
    rateProperty ({ commit, state }, personalData) {
      commit('updatePersonalData', personalData)
      commit('showLoader', true)
      let options = {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Referer': cloudApiReferer
        },
        body: JSON.stringify({
          type: state.collectedData.type.toUpperCase(),
          holder: { ...state.personalData },
          values: { ...state.collectedData },
          sendEmail: true,
          generatePdf: true,
          queueLead: window.prt.settings.queueActive || false,
          extraSteps: [...state.extraSteps]
        })
      }
      fetch(cloudApiBaseUrl + `/iframe/rateProperty?key=${cloudApiIframeKey}`, options)
        .then(response => response.json())
        .then(json => {
          commit('replaceLeadResponse', json)
          analytics.trackFinished(json.id)
          if (window.prt.settings.redirectOnSuccessUrl) {
            window.location = window.prt.settings.redirectOnSuccessUrl
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          commit('showLoader', false)
          commit('nextSlide')
        })
    }
  },
  modules: {
  }
})
