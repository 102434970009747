// stats.js
import { cloudApiBaseUrl, cloudApiDontTrack, cloudApiIframeKey } from '@/constants'
import { InteractionTracker } from './tracking/InteractionTracker'
import ConsentManager from '@/ConsentManager'

/* global ga, fbq, dataLayer */

// Bestehender Code für grundlegendes Tracking
const prtAnalytics = async step => {
  // Debug-Log hinzufügen
  console.log('prtAnalytics called with step:', step)
  console.log('Current consent status:', ConsentManager.getConsentStatus())

  // Prüfe Consent für Statistiken
  if (!ConsentManager.hasConsent('statistics')) {
    console.log('Statistics tracking skipped - no consent')
    return
  }

  const body = new URLSearchParams()
  const urlParams = new URLSearchParams(window.location.search)
  body.append('key', cloudApiIframeKey)
  body.append('step', step)
  body.append('utm_source', urlParams.get('utm_source'))
  body.append('utm_medium', urlParams.get('utm_medium'))
  body.append('utm_campaign', urlParams.get('utm_campaign'))
  body.append('utm_term', urlParams.get('utm_term'))
  body.append('utm_content', urlParams.get('utm_content'))

  console.log('Sending analytics request with body:', body.toString())

  try {
    const response = await fetch(cloudApiBaseUrl + '/iframe/createStatistic', {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: body
    })

    if (!response.ok) {
      console.error('Analytics request failed:', response.status, response.statusText)
    } else {
      console.log('Analytics request successful')
    }
  } catch (err) {
    console.error('Error sending analytics:', err)
  }
}

// Initialisierung des neuen Tracking-Systems
const interactionTracker = new InteractionTracker()

const trackSlideEnter = () => {
  // Slide Enter ist essential
  const slideType = this.slideType
  interactionTracker.trackInteraction('SLIDE_ENTER', slideType, {
    timestamp: Date.now(),
    userAgent: window.navigator.userAgent,
    screenSize: `${window.innerWidth}x${window.innerHeight}`,
    interactionCount: 0
  })
}

const trackSlideExit = (action, data) => {
  // Slide Exit ist essential
  interactionTracker.trackInteraction('SLIDE_EXIT', data.slideType, {
    action,
    timeSpent: data.timeSpent,
    timestamp: Date.now()
  })
}

const trackStep = async step => {
  if (cloudApiDontTrack === '1') return

  console.debug(step)

  // Marketing Tracking nur mit Marketing Consent
  if (ConsentManager.hasConsent('marketing')) {
    if (typeof ga !== 'undefined') {
      ga('send', 'event', 'PRTStep', 'click', step)
    }
    if (typeof fbq !== 'undefined') {
      fbq('trackCustom', 'PRTStep', { action: 'click', label: step })
    }
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({
        'event': 'prt_step',
        'action': 'click',
        'label': step
      })
    }
  }

  // Essential Tracking immer durchführen
  await interactionTracker.trackInteraction(
    'SLIDE_ENTER',
    step,
    { timestamp: Date.now() }
  )

  // Statistik Tracking nur mit Statistics Consent
  if (ConsentManager.hasConsent('statistics')) {
    await prtAnalytics(step)
  }
}

const trackFinished = async leadId => {
  if (cloudApiDontTrack === '1') return

  console.debug(leadId)

  // Marketing Tracking nur mit Marketing Consent
  if (ConsentManager.hasConsent('marketing')) {
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({
        'event': 'generate_lead',
        'value': '50',
        'currency': 'EUR',
        'transaction_id': leadId
      })
    }

    if (typeof ga !== 'undefined') {
      ga('send', 'event', 'generate_lead', 'click', leadId)
    }

    if (typeof fbq !== 'undefined') {
      fbq('track', 'Lead', {
        value: 50,
        currency: 'EUR',
        external_id: leadId
      })
    }
  }

  // Statistik Tracking nur mit Statistics Consent
  if (ConsentManager.hasConsent('statistics')) {
    const sessionSummary = interactionTracker.getSessionSummary()

    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({
        'event': 'lead_quality_score',
        'quality_score': sessionSummary.overallQuality,
        'interaction_count': sessionSummary.interactionCount,
        'session_duration': sessionSummary.duration,
        'lead_id': leadId
      })
    }
  }
}

export default {
  trackStep,
  trackFinished,
  trackInteraction: async (type, slideType, data) => {
    if (cloudApiDontTrack === '1') return

    // Prüfe Consent basierend auf Interaktionstyp
    const consentType = interactionTracker.getConsentTypeForInteraction(type)
    if (!ConsentManager.hasConsent(consentType)) {
      console.debug(`Skipping interaction tracking (no ${consentType} consent):`, type)
      return
    }

    await interactionTracker.trackInteraction(type, slideType, data)
  },
  getSessionSummary: () => {
    // Basis-Informationen immer verfügbar
    const summary = {
      sessionId: interactionTracker.sessionData.sessionId,
      duration: Date.now() - interactionTracker.sessionData.startTime
    }

    // Erweiterte Informationen nur mit Statistics Consent
    if (ConsentManager.hasConsent('statistics')) {
      return {
        ...summary,
        ...interactionTracker.getSessionSummary()
      }
    }

    return summary
  },
  getInteractionTracker: () => interactionTracker,
  // Neue Methoden
  trackSlideEnter,
  trackSlideExit
}
