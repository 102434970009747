<template>
  <div class="home-slide slide-base">
    <div class="slide-content">
      <div class="slide-inner">
        <!-- Header Section -->
        <div class="header-section">
          <h1 class="main-title" v-html="this.$prt.settings.text.start"></h1>
          <div class="slogan-container">
            <h2 class="slogan" v-html="this.$prt.settings.text.startSlogan1"></h2>
            <h1 class="slogan-highlight" v-html="this.$prt.settings.text.startSlogan2"></h1>
          </div>
        </div>

        <!-- Main Section -->
        <div class="main-section">
          <!-- Property Type Selection -->
          <div class="property-type-grid">
            <div
              v-for="type in filteredPropertyTypes"
              :key="type.id"
              class="property-card"
              :class="{ active: collectedData.chosenType === type.id }"
              @click="selectType(type.id)"
            >
              <div class="icon-container">
                <component :is="type.icon"/>
              </div>
              <span class="type-label">{{ type.label }}</span>
            </div>
          </div>

          <!-- DSGVO Section -->
          <transition name="fade">
            <div v-if="dsgvoVisible" class="dsgvo-section" :class="{ active: dsgvoVisible }">
              <div class="dsgvo-header">
                <h3>Ihre Einwilligung für ein besseres Nutzererlebnis</h3>
                <p class="subtitle">Diese Website verwendet verschiedene Technologien, um Ihnen eine präzise Immobilienbewertung zu ermöglichen.</p>
              </div>

              <!-- Primäre Action - rechtlich okay, solange nicht irreführend -->
              <div class="primary-action">
                <button class="accept-all-button" @click="acceptAll">
        <span class="button-content">
          Alle Cookies akzeptieren und fortfahren
        </span>
                  <span class="button-subtext">
          Ermöglicht die beste Nutzererfahrung
        </span>
                </button>
              </div>

              <div class="consent-options">
                <!-- Erforderlich -->
                <div class="consent-option primary">
                  <input
                    type="checkbox"
                    id="essential"
                    v-model="localConsent.essential"
                    readonly
                    @click="handleEssentialClick"
                  >
                  <div class="consent-content">
                    <label for="essential">
                      <span class="option-title">Technisch notwendige Cookies</span>
                      <span class="badge">Erforderlich</span>
                    </label>
                    <span class="info-text">
            Notwendig für die Grundfunktionen der Website. Ermöglicht:
            <ul>
              <li>Sichere Übertragung Ihrer Immobiliendaten</li>
              <li>Korrekte Berechnung der Bewertung</li>
              <li>Temporäre Speicherung Ihrer Eingaben</li>
            </ul>
          </span>
                  </div>
                </div>

                <!-- Statistiken -->
                <div class="consent-option">
                  <input
                    type="checkbox"
                    id="statistics"
                    v-model="localConsent.statistics"
                    :disabled="!localConsent.essential"
                  >
                  <div class="consent-content">
                    <label for="statistics">
                      <span class="option-title">Analyse & Verbesserungen</span>
                    </label>
                    <span class="info-text">
            Hilft uns, die Bewertungsqualität zu verbessern. Ermöglicht:
            <ul>
              <li>Analyse der Bewertungsgenauigkeit</li>
              <li>Verbesserung der Berechnungsmodelle</li>
              <li>Optimierung der Benutzerführung</li>
            </ul>
            Alle Daten werden anonymisiert verarbeitet.
          </span>
                  </div>
                </div>

                <!-- Marketing -->
                <div class="consent-option">
                  <input
                    type="checkbox"
                    id="marketing"
                    v-model="localConsent.marketing"
                    :disabled="!localConsent.essential"
                  >
                  <div class="consent-content">
                    <label for="marketing">
                      <span class="option-title">Personalisierung & Service</span>
                    </label>
                    <span class="info-text">
            Ermöglicht individuell abgestimmte Immobilienservices:
            <ul>
              <li>Marktrelevante Immobilieninformationen</li>
              <li>Passende Immobiliendienstleistungen</li>
              <li>Regionale Markteinschätzungen</li>
            </ul>
          </span>
                  </div>
                </div>
              </div>

              <div class="consent-actions">
                <button class="accept-selection" @click="acceptSelection">
                  Auswahl bestätigen
                </button>
                <button class="accept-essential text-button" @click="acceptEssential">
                  Nur technisch notwendige akzeptieren
                </button>
              </div>

              <div class="consent-footer">
                <p class="security-note">
                  <span class="icon">🔒</span>
                  Ihre Daten werden gemäß DSGVO geschützt.
                  <a @click.prevent="$modal.show('dsgvoCookie')" href="#" class="dsgvo-link">
                    Details in der Datenschutzerklärung
                  </a>
                </p>
              </div>
            </div>
          </transition>
        </div>

        <!-- Footer Section -->
        <div class="footer-section">
          <div class="powered-by">
            powered by <a href="https://www.leadmeup.de" target="_blank" rel="noopener">LeadMeUp</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

// HomeSlide.vue
<script>
import analytics from '@/stats'
import { mapState } from 'vuex'
import WohnungIcon from '@/assets/icons/wohnung.svg'
import HausIcon from '@/assets/icons/haus.svg'
import GrundstuckIcon from '@/assets/icons/grundstuck.svg'
import MieteIcon from '@/assets/icons/miete.svg'
import BusinessIcon from '@/assets/icons/industrie-oder-geschaeftsgebaeude.svg'
import slideMixin from '@/mixins/slideMixin'
import ConsentManager from '@/ConsentManager'

export default {
  name: 'HomeSlide',

  components: {
    WohnungIcon,
    HausIcon,
    GrundstuckIcon,
    MieteIcon,
    BusinessIcon
  },

  mixins: [slideMixin],

  data () {
    return {
      localConsent: {
        essential: true,
        statistics: false,
        marketing: false,
        thirdParty: false
      },
      isProcessing: false,
      dsgvoVisible: false,
      propertyTypes: [
        {
          id: 'apartment',
          label: 'Wohnung',
          icon: 'WohnungIcon'
        },
        {
          id: 'house',
          label: 'Haus',
          icon: 'HausIcon'
        },
        {
          id: 'land',
          label: 'Grundstück',
          icon: 'GrundstuckIcon'
        },
        {
          id: 'rent',
          label: 'Miete',
          icon: 'MieteIcon'
        },
        {
          id: 'business',
          label: 'Gewerbe',
          icon: 'BusinessIcon'
        }
      ]
    }
  },

  mounted () {
    this.dsgvoVisible = false
  },

  computed: {
    ...mapState(['collectedData']),

    dsgvoSectionVisible: {
      get () {
        return Boolean(this.isDsgvoVisible)
      },
      set (value) {
        this.isDsgvoVisible = Boolean(value)
      }
    },

    filteredPropertyTypes () {
      return this.propertyTypes.filter(type => {
        // Ihre bestehende Filterlogik
        const settings = this.$prt.settings
        if (!settings) return true

        switch (type.id) {
          case 'apartment':
            return settings.apartment !== false
          case 'house':
            return settings.house !== false
          case 'land':
            return settings.land !== false
          case 'rent':
            return settings.rent !== false
          case 'business':
            return settings.business !== false
          default:
            return true
        }
      })
    }
  },

  methods: {
    handleEssentialClick (event) {
      event.preventDefault()
    },

    async selectType (type) {
      if (this.isProcessing) return

      try {
        this.isProcessing = true
        console.log('HomeSlide - Type selected:', type)

        // Setze den Type im Store
        this.$store.commit('updateCollectedData', {
          chosenType: type,
          type: type
        })

        // Setze den Type im InteractionTracker
        const tracker = analytics.getInteractionTracker()
        tracker.setCurrentPropertyType(type)

        const consentStatus = await ConsentManager.getConsentStatus()
        console.log('Current consent status:', consentStatus)

        // Prüfe ob Consent nötig ist
        if (this.$commons.getSafe(() => this.$prt.settings.dsgvo.active) &&
          !consentStatus.essential) {
          console.log('Showing DSGVO section')
          this.dsgvoVisible = true

          // Warten auf DOM-Update
          await this.$nextTick()

          // Sanftes Scrollen zur DSGVO-Section
          const dsgvoSection = document.querySelector('.dsgvo-section')
          if (dsgvoSection) {
            const offset = 20
            const dsgvoPosition = dsgvoSection.getBoundingClientRect().top + window.pageYOffset - offset
            window.scrollTo({
              top: dsgvoPosition,
              behavior: 'smooth'
            })
          }
        } else {
          console.log('Consent already given, moving to next slide')
          this.$store.commit('nextSlide')
        }
      } catch (error) {
        console.error('Error in selectType:', error)
      } finally {
        this.isProcessing = false
      }
    },

    async handleConsent () {
      if (this.isProcessing) return

      try {
        this.isProcessing = true
        console.log('Handling consent with:', this.localConsent)

        // Erstelle eine bereinigte Kopie des Consent-Objekts
        const cleanConsent = {
          essential: true, // Immer true
          statistics: Boolean(this.localConsent.statistics),
          marketing: Boolean(this.localConsent.marketing),
          thirdParty: Boolean(this.localConsent.thirdParty)
        }

        await ConsentManager.updateConsent(cleanConsent)
        const updatedStatus = ConsentManager.getConsentStatus()
        console.log('Consent updated, new state:', updatedStatus)

        this.dsgvoVisible = false
        await this.$nextTick()
        this.$store.commit('nextSlide')
      } catch (error) {
        console.error('Error handling consent:', error)
      } finally {
        this.isProcessing = false
      }
    },

    acceptAll () {
      console.log('Accepting all consents')
      this.localConsent = {
        essential: true,
        statistics: true,
        marketing: true,
        thirdParty: true
      }
      this.handleConsent()
    },

    async acceptSelection () {
      console.log('Selection accepted, current consent:', this.localConsent)
      try {
        // Stelle sicher, dass essential true ist
        const cleanConsent = {
          essential: true,
          statistics: Boolean(this.localConsent.statistics),
          marketing: Boolean(this.localConsent.marketing),
          thirdParty: Boolean(this.localConsent.thirdParty)
        }

        console.log('Updating consent with:', cleanConsent)
        await ConsentManager.updateConsent(cleanConsent)
        console.log('Consent updated successfully')

        // Banner und Modal schließen
        this.dsgvoVisible = false
        this.$modal.hide('dsgvoCookie')

        // Warten auf DOM-Updates
        await this.$nextTick()
        console.log('Proceeding to next slide')
        this.$store.commit('nextSlide')
      } catch (error) {
        console.error('Error in acceptSelection:', error)
      }
    },

    acceptEssential () {
      console.log('Accepting only essential')
      this.localConsent = {
        essential: true,
        statistics: false,
        marketing: false,
        thirdParty: false
      }
      this.handleConsent()
    }
  },

  watch: {
    isDsgvoVisible: {
      immediate: true,
      handler (newValue) {
        console.log('DSGVO section visibility changed to:', Boolean(newValue))
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/_layout.scss';

.home-slide {
  .header-section {
    text-align: center;

    .main-title {
      color: var(--primary-color);
      font-weight: 600;
    }

    .slogan-container {
      max-width: 800px;
      margin: 0 auto;
    }

    .slogan {
      color: #4a5568;
      font-weight: 400;
      margin-bottom: 0.5rem;
    }

    .slogan-highlight {
      color: var(--secondary-color);
      font-weight: 600;
    }
  }

  .property-type-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 1.5rem;
    margin: 1.5rem 0;

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
  }

  .property-card {
    background: #f8fafc;
    border: 2px solid transparent;
    border-radius: 16px;
    padding: 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    height: auto;
    min-height: 44px;

    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    }

    &.active {
      background: rgba(23, 181, 160, 0.1);
      border-color: var(--secondary-color);
      box-shadow: 0 4px 12px rgba(23, 181, 160, 0.2);
    }

    .icon-container {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 768px) {
        width: 32px;
        height: 32px;
      }

      svg {
        width: 100%;
        height: 100%;
        display: block;

        .prt-icon-color {
          fill: var(--secondary-color, #17B5A0);
        }
      }
    }

    &:hover {
      .icon-container svg .prt-icon-color {
        fill: var(--secondary-color-dark, #149183);
      }
    }

    .type-label {
      font-size: 1.1rem;
      font-weight: 500;
      color: var(--primary-color);

      @media (max-width: 768px) {
        font-size: 0.9rem;
      }
    }

    @media (max-width: 768px) {
      padding: 1rem;
    }
  }

  .dsgvo-section {
    background: #ffffff;
    border-radius: 16px;
    padding: 1.5rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);

    .dsgvo-header {
      text-align: center;
      margin-bottom: 1.5rem;

      h3 {
        color: var(--primary-color);
        font-size: 1.4rem;
        margin-bottom: 0.5rem;
        font-weight: 600;
      }

      .subtitle {
        color: #666;
        font-size: 1rem;
        line-height: 1.4;
      }
    }

    .consent-options {
      .consent-option {
        background: #f8fafc;
        border: 1px solid #e2e8f0;
        border-radius: 12px;
        padding: 1rem;
        margin-bottom: 1rem;
        transition: all 0.2s ease;

        &:hover {
          border-color: var(--secondary-color);
          transform: translateY(-1px);
        }

        &.primary {
          background: rgba(23, 181, 160, 0.05);
          border-color: var(--secondary-color);
        }

        .consent-content {
          margin-left: 2rem;
        }

        label {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0.3rem;
        }

        .option-title {
          font-weight: 500;
          color: #1a202c;
        }

        .badge {
          font-size: 0.75rem;
          padding: 0.2rem 0.5rem;
          border-radius: 12px;
          background: #e2e8f0;
          color: #4a5568;

          &.recommended {
            background: var(--secondary-color);
            color: white;
          }
        }

        .info-text {
          font-size: 0.9rem;
          line-height: 1.5;
          color: #4a5568;
          margin-top: 0.5rem;
        }
      }

      .consent-actions {
        // Hierarchie der Buttons angepasst
        .primary-button {
          background: var(--primary-color);

          .button-subtext {
            display: none; // Entfernen des beeinflussenden Subtexts
          }
        }

        .secondary-actions {
          button {
            &.accept-all {
              color: var(--primary-color);
              border: 1px solid currentColor;
              background: transparent;
            }
          }
        }

        .info-text {
          color: #666;
          font-size: 0.9rem;
        }

        input[type="checkbox"] {
          width: 20px;
          height: 20px;
          position: absolute;
          margin-top: 0.25rem;
          accent-color: var(--secondary-color);
        }
      }
    }

    .primary-action {
      margin: 1rem 0;
      text-align: center;

      .accept-all-button {
        background: var(--secondary-color);
        color: white;
        border: none;
        border-radius: 12px;
        padding: 1rem 2rem;
        width: 100%;
        max-width: 400px;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          background: var(--secondary-color-dark);
          transform: translateY(-2px);
        }

        .button-content {
          display: block;
          font-size: 1.1rem;
          font-weight: 600;
          margin-bottom: 0.25rem;
        }

        .button-subtext {
          display: block;
          font-size: 0.8rem;
          opacity: 0.9;
        }
      }
    }

    .consent-actions {
      margin-top: 2rem;

      .primary-button {
        width: 100%;
        background: var(--secondary-color);
        color: white;
        border: none;
        border-radius: 12px;
        padding: 1rem;
        margin-bottom: 1rem;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          background: var(--secondary-color-dark);
          transform: translateY(-2px);
        }

        .button-content {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          font-size: 1.1rem;

          .button-icon {
            margin-right: 0.5rem;
          }
        }

        .button-subtext {
          display: block;
          font-size: 0.8rem;
          opacity: 0.9;
          margin-top: 0.2rem;
        }
      }

      .secondary-actions {
        display: flex;
        justify-content: center;
        gap: 1rem;

        button {
          padding: 0.5rem 1rem;
          font-size: 0.9rem;
          border-radius: 8px;
          cursor: pointer;
          transition: all 0.2s ease;

          &.accept-selection {
            background: #f1f5f9;
            border: 1px solid #e2e8f0;
            color: #1a202c;

            &:hover {
              background: #e2e8f0;
            }
          }

          &.text-button {
            background: none;
            border: none;
            color: #64748b;

            &:hover {
              color: #1a202c;
            }
          }
        }
      }
    }

    .consent-footer {
      margin-top: 1.5rem;
      text-align: center;

      .security-note {
        font-size: 0.9rem;
        color: #64748b;

        .icon {
          margin-right: 0.3rem;
        }

        .dsgvo-link {
          color: var(--secondary-color);
          text-decoration: none;
          font-weight: 500;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  // Responsive Anpassungen
  @media (max-width: 768px) {
    .dsgvo-section {
      padding: 1rem;

      .dsgvo-header {
        h3 {
          font-size: 1.2rem;
        }
      }

      .consent-options {
        .consent-option {
          padding: 0.75rem;
        }
      }

      .consent-actions {
        .secondary-actions {
          flex-direction: column;
          gap: 0.5rem;

          button {
            width: 100%;
            padding: 0.75rem;
          }
        }
      }
    }
  }

  .rate-now-button {
    width: 100%;
    padding: 1rem;
    background: var(--secondary-color);
    color: white;
    border: none;
    border-radius: 12px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    min-height: 44px;

    &:disabled {
      background: #cbd5e0;
      cursor: not-allowed;
      transform: none !important;
    }

    &:not(:disabled):hover {
      background: var(--secondary-color-dark);
      transform: translateY(-2px);
    }

    .button-icon {
      font-size: 1.25rem;
      transition: transform 0.3s ease;
    }

    &:hover .button-icon {
      transform: translateX(4px);
    }

    @media (max-width: 768px) {
      font-size: 1rem;
      padding: 0.875rem;
    }
  }

  .dsgvo-section {
    background: #f8fafc;
    border-radius: 16px;
    padding: 2rem;
    margin: 2rem 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    &.active {
      opacity: 1;
      transform: translateY(0);
    }

    .consent-options {
      margin: 1.5rem 0;

      .consent-option {
        display: flex;
        align-items: flex-start;
        margin: 1rem 0;
        padding: 0.5rem;
        border-radius: 8px;
        transition: background-color 0.2s ease;

        &:hover {
          background-color: rgba(0, 0, 0, 0.02);
        }

        input[type="checkbox"] {
          margin-right: 1rem;
          margin-top: 0.25rem;
          width: 18px;
          height: 18px;
          accent-color: var(--secondary-color);
          cursor: pointer;

          &:disabled {
            cursor: not-allowed;
            opacity: 0.6;
          }
        }

        label {
          font-weight: 500;
          margin-right: 0.5rem;
          cursor: pointer;
        }

        .info-text {
          color: #666;
          font-size: 0.9rem;
          margin-left: 0.5rem;
        }
      }
    }

    .consent-text {
      margin: 1.5rem 0;
      color: #666;
      font-size: 0.9rem;
      line-height: 1.5;

      .dsgvo-link {
        color: var(--secondary-color);
        text-decoration: none;
        font-weight: 500;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .consent-actions {
      display: flex;
      gap: 1rem;
      justify-content: flex-end;
      margin-top: 2rem;

      button {
        padding: 0.75rem 1.5rem;
        border: none;
        border-radius: 8px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;
        font-size: 0.95rem;

        &.accept-all {
          background: var(--secondary-color);
          color: white;

          &:hover {
            background: var(--secondary-color-dark);
            transform: translateY(-1px);
          }
        }

        &.accept-selection {
          background: var(--primary-color);
          color: white;

          &:hover {
            background: var(--primary-color-dark);
            transform: translateY(-1px);
          }
        }

        &.accept-essential {
          background: #64748b;
          color: white;

          &:hover {
            background: #475569;
            transform: translateY(-1px);
          }
        }
      }
    }

    // Responsive Styles
    @media (max-width: 768px) {
      padding: 1.5rem;
      margin: 1rem 0;

      .consent-actions {
        flex-direction: column;
        gap: 0.75rem;

        button {
          width: 100%;
          padding: 0.875rem;
        }
      }

      .consent-option {
        flex-direction: column;

        input[type="checkbox"] {
          margin-bottom: 0.5rem;
        }

        .info-text {
          margin-left: 0;
          margin-top: 0.25rem;
        }
      }
    }

    // Animation
    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    animation: slideIn 0.3s ease-out;
  }

  // Transition für das Ein- und Ausblenden
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
    transform: translateY(10px);
  }

  .footer-section {
    .powered-by {
      text-align: center;
      font-size: 0.8rem;
      color: #666;

      a {
        color: inherit;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
