<template>
  <div class="email-slide slide-base" data-step-name="E-Mail">
    <div class="slide-content">
      <div class="slide-inner">
        <div class="main-section">
          <!-- Header Section -->
          <div class="header-section">
            <h1 class="slide-title" v-html="this.$prt.settings.text.emailTitle"></h1>
            <div class="security-badge">
              <i class="fas fa-lock security-icon"></i>
              <span>Sichere Übertragung</span>
            </div>
          </div>

          <!-- Main Form -->
          <form @submit.prevent="next" ref="emailForm" class="email-form">
            <div class="form-group">
              <div class="input-container">
                <input
                  v-model="emailData"
                  type="email"
                  id="email"
                  required
                  class="form-input"
                  pattern="[A-Za-z0-9._%+\-]+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}"
                  @input="onInput"
                  @focus="onFocus"
                  @blur="onBlur"
                >
                <label for="email" class="input-label">Ihre E-Mail-Adresse</label>
              </div>
              <p class="form-hint" v-html="this.$prt.settings.text.emailBody"></p>
            </div>

            <div class="form-actions">
              <button type="submit" class="submit-button">
                <span class="button-icon">→</span>
              </button>
            </div>
          </form>

          <!-- Back Button (Mobile) -->
          <div class="mobile-nav">
            <button type="button" class="nav-button prev-button" @click="prev">
              <span class="button-icon">←</span>
            </button>
          </div>
        </div>

        <div class="footer-section"></div>
      </div>
    </div>
  </div>
</template>

<script>
import slideMixin from '@/mixins/slideMixin'
import analytics from '@/stats'

export default {
  name: 'EmailSlide',
  mixins: [slideMixin],
  computed: {
    isTransitioning () {
      return this.$store.state.isTransitioning
    },
    slideType () {
      // Hier holen wir uns collectedData sicher
      const collectedData = this.$store.state.collectedData || {}
      const type = collectedData.type || ''

      if (!type) {
        return 'E-Mail'
      }

      return `${type.charAt(0).toUpperCase() + type.slice(1)} - E-Mail`
    }
  },
  data () {
    return {
      emailData: '',
      inputTimestamps: [],
      corrections: 0,
      focusTimestamp: null,
      previousValue: '',
      validationAttempts: 0,
      validationSuccesses: 0
    }
  },
  methods: {
    onInput (e) {
      // Timestamp für jeden Tastenanschlag
      this.inputTimestamps.push(Date.now())

      // Erkennung von Korrekturen
      if (e.target.value.length < this.previousValue.length) {
        this.corrections++
      }
      this.previousValue = e.target.value

      analytics.trackInteraction('INPUT_CHANGE', this.slideType, {
        value: e.target.value,
        timestamps: this.inputTimestamps,
        corrections: this.corrections,
        typingDuration: Date.now() - this.inputTimestamps[0],
        typingMetrics: {
          totalKeystrokes: this.inputTimestamps.length,
          correctionRate: this.corrections / this.inputTimestamps.length,
          inputLength: e.target.value.length,
          isAutoFilled: e.target.matches(':-webkit-autofill') ||
            this.inputTimestamps.length < e.target.value.length // Erkennung von Paste/Autofill
        }
      })
    },

    onFocus () {
      this.focusTimestamp = Date.now()
      analytics.trackInteraction('FIELD_FOCUS', this.slideType, {
        timestamp: this.focusTimestamp
      })
    },

    onBlur () {
      if (this.focusTimestamp) {
        analytics.trackInteraction('FIELD_BLUR', this.slideType, {
          value: this.emailData,
          focusDuration: Date.now() - this.focusTimestamp,
          corrections: this.corrections
        })
      }
    },

    validateEmail () {
      this.validationAttempts++
      const isValid = this.$refs.emailForm.checkValidity()

      if (isValid) {
        this.validationSuccesses++
      }

      analytics.trackInteraction(
        isValid ? 'VALIDATION_SUCCESS' : 'VALIDATION_ERROR',
        this.slideType,
        {
          value: this.emailData,
          attempts: this.validationAttempts,
          successes: this.validationSuccesses,
          timestamp: Date.now(),
          typingAnalysis: {
            timestamps: this.inputTimestamps,
            corrections: this.corrections,
            totalTime: this.inputTimestamps.length > 0
              ? Date.now() - this.inputTimestamps[0]
              : 0
          }
        }
      )

      return isValid
    },

    next () {
      if (!this.validateEmail()) {
        this.$refs.emailForm.reportValidity()
        return
      }

      // Email an den Store übergeben
      this.$store.commit('updatePersonalData', {
        email: this.emailData
      })

      analytics.trackInteraction('SLIDE_COMPLETE', this.slideType, {
        value: this.emailData,
        totalTime: this.inputTimestamps.length > 0
          ? Date.now() - this.inputTimestamps[0]
          : 0,
        interactionMetrics: {
          corrections: this.corrections,
          validationAttempts: this.validationAttempts,
          validationSuccesses: this.validationSuccesses,
          typingPattern: this.inputTimestamps,
          totalKeystrokes: this.inputTimestamps.length,
          averageKeystrokeTime: this.inputTimestamps.length > 1
            ? (this.inputTimestamps[this.inputTimestamps.length - 1] - this.inputTimestamps[0]) / this.inputTimestamps.length
            : 0,
          focusDuration: this.focusTimestamp ? Date.now() - this.focusTimestamp : 0
        },
        inputQuality: {
          isLikelyAutoFilled: this.inputTimestamps.length < this.emailData.length,
          hasNaturalTiming: this.inputTimestamps.length > 1 &&
            (Date.now() - this.inputTimestamps[0]) > 500,
          consistency: this.calculateInputConsistency()
        }
      })

      this.$store.commit('nextSlide')
    },

    calculateInputConsistency () {
      if (this.inputTimestamps.length < 2) return 1

      const intervals = []
      for (let i = 1; i < this.inputTimestamps.length; i++) {
        intervals.push(this.inputTimestamps[i] - this.inputTimestamps[i - 1])
      }

      const avg = intervals.reduce((a, b) => a + b, 0) / intervals.length
      const variance = intervals.reduce((acc, val) => acc + Math.pow(val - avg, 2), 0) / intervals.length
      const stdDev = Math.sqrt(variance)

      // Niedrige Standardabweichung deutet auf Bot/Automation hin
      return Math.min(1, stdDev / avg)
    },

    prev () {
      if (!this.isTransitioning) {
        analytics.trackInteraction('SLIDE_EXIT', this.slideType, {
          action: 'back',
          partialValue: this.emailData,
          timestamp: Date.now()
        })
        this.$store.commit('prevSlide')
      }
    }
  },
  beforeDestroy () {
    if (this.emailData) {
      analytics.trackInteraction('SLIDE_EXIT', this.slideType, {
        finalValue: this.emailData,
        timestamp: Date.now()
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/_layout.scss';

.email-slide {
  .header-section {
    text-align: center;
    margin-bottom: 2rem;

    .slide-title {
      font-size: clamp(1.5rem, 4vw, 2rem);
      color: var(--primary-color);
      margin-bottom: 1rem;
      font-weight: 600;
      line-height: 1.3;
    }
  }

  .security-badge {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background: rgba(0, 128, 0, 0.1);
    border-radius: 20px;
    color: #006400;
    font-size: 0.875rem;

    .security-icon {
      margin-right: 0.5rem;
    }
  }

  .email-form {
    .form-group {
      margin-bottom: 1.5rem;
    }

    .input-container {
      position: relative;
      margin-bottom: 0.5rem;
    }

    .form-input {
      width: 100%;
      padding: 1rem;
      border: 2px solid #e0e0e0;
      border-radius: 12px;
      font-size: 1rem;
      min-height: 44px;
      transition: all 0.3s ease;

      &:focus {
        border-color: var(--secondary-color);
        box-shadow: 0 0 0 3px rgba(23, 181, 160, 0.1);
        outline: none;
      }

      &:focus ~ .input-label,
      &:not(:placeholder-shown) ~ .input-label {
        transform: translateY(-24px) scale(0.85);
        color: var(--secondary-color);
      }

      &:invalid:not(:placeholder-shown) {
        border-color: #fc5c65;
      }
    }

    .input-label {
      position: absolute;
      left: 16px;
      top: 16px;
      color: #666;
      transition: transform 0.3s ease, color 0.3s ease;
      transform-origin: 0 0;
      pointer-events: none;
      background: white;
      padding: 0 4px;
    }

    .form-hint {
      font-size: 0.875rem;
      color: #666;
      margin-top: 0.5rem;
      text-align: left;
    }
  }

  .form-actions {
    margin-top: 2rem;
  }

  .submit-button,
  .nav-button {
    width: 100%;
    padding: 1rem;
    border: none;
    border-radius: 12px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    min-height: 44px;

    &:hover {
      transform: translateY(-1px);
    }

    .button-icon {
      font-size: 1.25rem;
      transition: transform 0.3s ease;
    }
  }

  .submit-button {
    background: var(--secondary-color);
    color: white;

    &:hover {
      background: var(--secondary-color-dark);

      .button-icon {
        transform: translateX(4px);
      }
    }
  }

  .mobile-nav {
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #eee;
  }

  @media (max-width: 768px) {
    .main-section {
      padding: 1rem;
    }

    .slide-title {
      font-size: 1.5rem;
    }

    .security-badge {
      font-size: 0.8rem;
      padding: 0.4rem 0.8rem;
    }

    .form-input {
      padding: 0.875rem;
      font-size: 0.95rem;
    }

    .form-hint {
      font-size: 0.8rem !important;
    }

    .submit-button,
    .nav-button {
      padding: 0.875rem;
      font-size: 0.95rem;
    }
  }
}
</style>
