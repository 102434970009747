<template>
  <div class="home-slide slide-base" data-iframe-size data-step-name="Start">
    <swiper
      ref="prtSwiper"
      class="prt-swiper"
      :options="swiperOption"
      :style="swiperStyles"
      @slideChange="onSlideChange"
      @transitionEnd="onTransitionEnd"
    >
      <PrtSlideWrapper :show-back-button="false">
        <HomeSlide @consent-given="handleConsentGiven"/>
      </PrtSlideWrapper>
      <PrtSlideWrapper v-if="this.$prt.getSetting('askReason', true)">
        <ReasonSlide/>
      </PrtSlideWrapper>
      <PrtSlideWrapper v-if="this.$prt.getSetting('askReason', true) && collectedData.reason === 'Verkauf'">
        <ReasonWhenSlide/>
      </PrtSlideWrapper>

      <!-- Ask for Address -->
      <PrtSlideWrapper :show-back-button-mobile="false">
        <MapsSlide v-if="loadMapsSlide" :title="this.$prt.settings.text.region"/>
      </PrtSlideWrapper>

      <template v-if="collectedData.type === 'apartment'">
        <PrtSlideWrapper :show-back-button-mobile="false">
          <LivingAreaSlide anaytics-key="apartment-living-area" :title="this.$prt.settings.text.apartment.livingArea"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper>
          <FloorSlide :title="this.$prt.settings.text.apartment.floor"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-back-button-mobile="false">
          <RoomCountSlide :title="this.$prt.settings.text.apartment.roomCount"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-back-button-mobile="false">
          <ConstructionYearSlide :title="this.$prt.settings.text.apartment.constructionYear"/>
        </PrtSlideWrapper>
      </template>

      <template v-if="collectedData.type === 'house'">
        <PrtSlideWrapper>
          <HouseTypeSlide :title="this.$prt.settings.text.house.houseType"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-back-button-mobile="false">
          <SiteAreaSlide :title="this.$prt.settings.text.house.siteArea"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-back-button-mobile="false">
          <LivingAreaSlide :title="this.$prt.settings.text.house.livingArea"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper>
          <FloorCountSlide :title="this.$prt.settings.text.house.floor"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-back-button-mobile="false">
          <RoomCountSlide :title="this.$prt.settings.text.house.roomCount"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-back-button-mobile="false">
          <ConstructionYearSlide :title="this.$prt.settings.text.house.constructionYear"/>
        </PrtSlideWrapper>
      </template>

      <template v-if="collectedData.type === 'land'">
        <PrtSlideWrapper :show-back-button-mobile="false">
          <LandAreaSlide :title="this.$prt.settings.text.land.siteArea"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper>
          <DevelopedPropertySlide :title="this.$prt.settings.text.land.developedProperty"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper>
          <ConstructionPossibilities :title="this.$prt.settings.text.land.constructionPossibilities"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper>
          <SiteLayoutSlide :title="this.$prt.settings.text.land.siteLayout"/>
        </PrtSlideWrapper>
      </template>

      <template v-if="this.isOneOf(collectedData.type, 'rent', 'rent_house', 'rent_apartment')">
        <PrtSlideWrapper>
          <RentTypeSlide :title="this.$prt.settings.text.rent.rentType"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-back-button-mobile="false">
          <LivingAreaSlide :title="this.$prt.settings.text.rent.livingArea"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-next-button-mobile="true">
          <RentCategorySlide :title="this.$prt.settings.text.rent.rentCategory"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-back-button-mobile="false">
          <ConstructionYearSlide :title="this.$prt.settings.text.rent.constructionYear"/>
        </PrtSlideWrapper>
      </template>

      <template v-if="collectedData.type === 'business'">
        <PrtSlideWrapper>
          <BusinessTypeSlide :title="this.$prt.settings.text.business.businessType"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-back-button-mobile="false">
          <LivingAreaSlide :title="this.$prt.settings.text.business.livingArea"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-back-button-mobile="false">
          <SiteAreaSlide :title="this.$prt.settings.text.business.siteArea"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-next-button-mobile="true">
          <BusinessIsRentSlide :title="this.$prt.settings.text.business.isRent"/>
        </PrtSlideWrapper>
        <PrtSlideWrapper :show-back-button-mobile="false">
          <ConstructionYearSlide :title="this.$prt.settings.text.business.constructionYear"/>
        </PrtSlideWrapper>
      </template>

      <template v-if="this.$prt.settings.extraSteps">
        <PrtSlideWrapper :key="index"
                         v-for="(extraStep, index) in this.$prt.settings.extraSteps.filter(ex => ex.activeTypes.includes(collectedData.type))">
          <ExtraStepSlide :index="index" :answers="extraStep.answers" :title="extraStep.title"/>
        </PrtSlideWrapper>
      </template>

      <!-- Some static slides -->
      <PrtSlideWrapper :show-back-button-mobile="false">
        <EmailSlide :title="this.$prt.settings.text.emailTitle"/>
      </PrtSlideWrapper>
      <PrtSlideWrapper :show-back-button-mobile="false">
        <PersonalDataSlide
          v-if="loadPersonalDataSlide"
          ref="personalDataSlide"
          @component-ready="onPersonalDataSlideReady"
        />
      </PrtSlideWrapper>
      <PrtSlideWrapper v-if="this.$prt.settings.extraSlide">
        <ExtraSlide/>
      </PrtSlideWrapper>
      <PrtSlideWrapper>
        <ResultsSlide v-if="this.$prt.settings.rateProperty && !this.$prt.settings.queueActive"/>
        <ResultsDeactivatedSlide v-else/>
      </PrtSlideWrapper>

      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>

    <!-- Dialog -->
    <v-dialog name="dialog"/>

    <!-- Preloader Modal -->
    <modal name="loader" :clickToClose="false">
      <div class="loader-content-wrapper">
        <div class="loader-content">
          <h1 class="title">{{ loaderText }}</h1>
          <GoogleLoader/>
        </div>
      </div>
    </modal>

    <!-- Sms Modal -->
    <modal name="sms" :scrollable="false" height="auto" :adaptive="true" width="425px" :clickToClose="false">
      <div class="t-left" style="padding: 20px;">
        <h1 class="title">Bestätigen Sie Ihre Rufnummer</h1>
        <p>Geben Sie den an Ihre Rufnummer gesendeten Bestätigungscode ein</p>
        <hr/>
        <form ref="smsCodeForm" id="sms-code-data" @submit="verifySms">
          <input class="prt-form-input" v-model="smsCode" required/>
          <div style="display: flex; justify-content: space-around; margin-top: 30px;">
            <FancyButton type="next" @click="verifySms()">Bestätigen</FancyButton>
            <FancyButton type="prev" @click="$modal.hide('sms')">Abbrechen</FancyButton>
          </div>
        </form>
      </div>
    </modal>

    <modal name="smsWentWrong" :scrollable="false" height="auto" :adaptive="true" width="400px" :clickToClose="false">
      <div class="t-left" style="padding: 20px;">
        <h1 class="title">Etwas ist schief gelaufen</h1>
        <hr/>
        <div style="display: flex; justify-content: space-around; margin-top: 30px;">
          <FancyButton type="prev" @click="$modal.hide('smsWentWrong')">OK</FancyButton>
        </div>
      </div>
    </modal>

    <modal name="smsInvalid" :scrollable="false" height="auto" :adaptive="true" width="400px" :clickToClose="false">
      <div class="t-left" style="padding: 20px;">
        <h1 class="title">Bitte verwenden Sie einen gültigen Code</h1>
        <hr/>
        <div style="display: flex; justify-content: space-around; margin-top: 30px;">
          <FancyButton type="next" @click="onClickSmsInvalid">Wiederholen</FancyButton>
          <FancyButton type="prev" @click="$modal.hide('smsInvalid')">Stornieren</FancyButton>
        </div>
      </div>
    </modal>

    <!-- Privacy Modal -->
    <modal name="privacy" :scrollable="false" height="60%" width="80%" :styles="'overflow-y: scroll'">
      <div class="t-left" style="padding: 20px;">
        <h1 class="title">Datenschutz</h1>
        <hr/>
        <p class="t-left" v-html="this.$prt.settings.privacyPolicy"/>
      </div>
    </modal>

    <!-- DSGVO Cookie Modal -->
    <modal name="dsgvoCookie" :scrollable="false" height="60%" width="80%" :styles="'overflow-y: scroll'">
      <div class="t-left" style="padding: 20px;">
        <h1 class="title">Erklärung zum Datenschutz</h1>
        <hr/>
        <DsgvoPrivacyCookieContent/>
      </div>
    </modal>

  </div>
</template>

<script>
import { InteractionTypes } from '@/tracking/constants'
import { mapState } from 'vuex'
import HomeSlide from '@/slides/HomeSlide.vue'
import LivingAreaSlide from '@/slides/apartment/LivingAreaSlide'
import PrtSlideWrapper from '@/components/PrtSlideWrapper'
import FloorSlide from '@/slides/apartment/FloorSlide'
import MapsSlide from '@/slides/MapsSlide'
import PersonalDataSlide from '@/slides/PersonalDataSlide'
import RoomCountSlide from '@/slides/apartment/RoomCountSlide'
import ConstructionYearSlide from '@/slides/apartment/ConstructionYearSlide'
import GoogleLoader from '@/components/icons/GoogleLoader'
import ResultsSlide from '@/slides/ResultsSlide'
import ResultsDeactivatedSlide from '@/slides/ResultsDeactivatedSlide'
import ExtraSlide from '@/slides/ExtraSlide'
import ReasonSlide from '@/slides/ReasonSlide'
import HouseTypeSlide from '@/slides/house/HouseTypeSlide'
import SiteAreaSlide from '@/slides/house/SiteAreaSlide'
import FloorCountSlide from '@/slides/house/FloorCountSlide'
import LandAreaSlide from '@/slides/land/LandAreaSlide'
import DevelopedPropertySlide from '@/slides/land/DevelopedPropertySlide'
import ConstructionPossibilities from '@/slides/land/ConstructionPossibilitiesSlide'
import SiteLayoutSlide from '@/slides/land/SiteLayoutSlide'
import RentTypeSlide from '@/slides/rent/RentTypeSlide'
import RentCategorySlide from '@/slides/rent/RentCategorySlide'
import analytics from '@/stats'
import ReasonWhenSlide from '@/slides/ReasonWhenSlide'
import BusinessTypeSlide from '@/slides/business/BusinessTypeSlide'
import FancyButton from '@/components/FancyButton'
import BusinessIsRentSlide from '@/slides/business/BusinessIsRentSlide'
import EmailSlide from '@/slides/EmailSlide'
import ExtraStepSlide from '@/slides/ExtraStepSlide'
import DsgvoPrivacyCookieContent from '@/slides/DsgvoPrivacyCookieContent'
import { cloudApiBaseUrl, cloudApiIframeKey } from '../constants'

export default {
  name: 'home',
  components: {
    EmailSlide,
    BusinessIsRentSlide,
    BusinessTypeSlide,
    ReasonWhenSlide,
    DsgvoPrivacyCookieContent,
    RentCategorySlide,
    RentTypeSlide,
    SiteLayoutSlide,
    ConstructionPossibilities,
    FancyButton,
    DevelopedPropertySlide,
    LandAreaSlide,
    FloorCountSlide,
    SiteAreaSlide,
    HouseTypeSlide,
    ReasonSlide,
    ExtraSlide,
    ResultsDeactivatedSlide,
    ResultsSlide,
    GoogleLoader,
    ConstructionYearSlide,
    RoomCountSlide,
    PersonalDataSlide,
    MapsSlide,
    FloorSlide,
    PrtSlideWrapper,
    HomeSlide,
    LivingAreaSlide,
    ExtraStepSlide
  },

  data () {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar',
          progressbarFillClass: 'swiper-pagination-progressbar-fill',
          renderProgressbar: function (className) {
            const activeSlide = document.querySelector('.swiper-slide-active')
            if (activeSlide && (
              activeSlide.querySelector('.maps-slide') ||
              activeSlide.querySelector('.personal-data-slide')
            )) {
              return ''
            }
            return '<span class="' + className + '"></span>'
          }
        },
        allowTouchMove: false,
        autoHeight: true,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        resistance: false,
        followFinger: false,
        simulateTouch: false,
        watchOverflow: true,
        longSwipesRatio: 0.1,
        touchRatio: 0,
        preventInteractionOnTransition: true,
        speed: 300,
        direction: 'horizontal',
        effect: 'slide',
        watchSlidesProgress: true,
        on: {
          init: function () {
            this.update()
            if (window.parentIFrame) {
              requestAnimationFrame(() => {
                const height = document.querySelector('.swiper-slide-active')?.offsetHeight || 0
                window.parentIFrame.resize(height)
              })
            }
          },
          slideChange: function () {
            const activeSlide = document.querySelector('.swiper-slide-active')
            if (activeSlide) {
              const progressBar = document.querySelector('.swiper-pagination-progressbar')
              if (progressBar) {
                progressBar.style.display = activeSlide.querySelector('.maps-slide, .personal-data-slide') ? 'none' : 'block'
              }
              if (window.parentIFrame) {
                setTimeout(() => {
                  window.parentIFrame.resize(activeSlide.offsetHeight)
                }, 50)
              }
            }
          },
          transitionEnd: function () {
            this.update()
          }
        }
      },
      loaderText: 'Ihre Immobilie wird bewertet',
      smsCode: '',
      loadMapsSlide: false,
      loadPersonalDataSlide: true,
      isNavigating: false
    }
  },

  computed: {
    ...mapState(['currentSlide', 'collectedData', 'showLoader', 'twilioSId']),
    swiper () {
      return this.$refs.prtSwiper.swiper
    },
    swiperStyles () {
      return {
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column'
      }
    }
  },

  created () {
    this.$store.commit('setTransitioning', false)
    window.addEventListener('load', this.handleWindowLoad)

    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('preselect') && this.$commons.getAllLeadTypes().includes(urlParams.get('preselect').toUpperCase())) {
      this.collectedData.type = urlParams.get('preselect').toLowerCase()
      this.$store.commit('nextSlide')

      if (window.parentIFrame) {
        setTimeout(() => {
          window.parentIFrame.getParentProps((parentProps) => {
            window.parentIFrame.scrollTo(0, parentProps.offsetTop)
          })
        }, 200)
      }
    }
  },

  async mounted () {
    console.log('Home view mounted')

    this.loadMapsSlide = true
    this.loadPersonalDataSlide = true

    // Reset navigation flags
    this.$store.commit('setTransitioning', false)
    this.isNavigating = false

    await this.trackStep('Anfang')

    // Initialisierung des Swipers
    if (this.$refs.prtSwiper?.swiper) {
      const swiper = this.$refs.prtSwiper.swiper
      swiper.update()
      await swiper.slideTo(this.currentSlide, 0)
      this.safeIframeResize()
    }
  },

  beforeDestroy () {
    window.removeEventListener('load', this.handleWindowLoad)
  },

  methods: {
    onSlideChange () {
      console.log('Slide changed event')
      this.safeIframeResize()
    },

    onTransitionEnd () {
      console.log('Transition ended event')
      if (this.$refs.prtSwiper?.swiper) {
        this.$refs.prtSwiper.swiper.update()
      }
    },

    async handleConsentGiven () {
      console.log('Consent given, handling navigation')

      try {
        if (this.$refs.prtSwiper?.swiper) {
          const swiper = this.$refs.prtSwiper.swiper

          // Warten auf DOM-Updates
          await this.$nextTick()

          // Swiper aktualisieren
          swiper.update()

          // Zum aktuellen Slide navigieren
          console.log('Navigating to slide:', this.currentSlide)
          await swiper.slideTo(this.currentSlide, 300)

          // Warten auf Animation
          await new Promise(resolve => setTimeout(resolve, 350))

          // Swiper nochmal aktualisieren
          swiper.update()
          this.safeIframeResize()

          console.log('Navigation completed')
        }
      } catch (error) {
        console.error('Error during consent navigation:', error)
      }
    },

    safeIframeResize () {
      if (window.parentIFrame) {
        requestAnimationFrame(() => {
          const activeSlide = document.querySelector('.swiper-slide-active')
          if (activeSlide) {
            window.parentIFrame.resize(activeSlide.offsetHeight)
          }
        })
      }
    },

    handleWindowLoad () {
      if (this.$refs.prtSwiper && this.$refs.prtSwiper.swiper) {
        this.$refs.prtSwiper.swiper.update()
      }
    },

    async onPersonalDataSlideReady () {
      console.log('PersonalDataSlide ready event received')
      await this.$nextTick()
      this.checkPersonalDataSlide()
    },

    checkPersonalDataSlide () {
      if (this.$refs.personalDataSlide &&
        !this.$refs.personalDataSlide.simulationStarted &&
        typeof this.$refs.personalDataSlide?.startRating === 'function') {
        const activeSlide = document.querySelector(
          '.swiper-slide-active .prt-slide [data-step-name]'
        )

        if (activeSlide && activeSlide.dataset.stepName === 'Persönliche Daten') {
          this.$refs.personalDataSlide.startRating()
        }
      }
    },

    isOneOf (source, ...withStrings) {
      if (typeof source === 'string') {
        const sourceUpper = source.toUpperCase()
        if (Array.isArray(withStrings)) {
          for (let i = 0; i < withStrings.length; i++) {
            if (typeof withStrings[i] === 'string' && withStrings[i].toUpperCase() === sourceUpper) {
              return true
            }
          }
        }
      }
      return false
    },

    async verifySms () {
      if (!this.$refs.smsCodeForm.checkValidity()) {
        this.$refs.smsCodeForm.reportValidity()
        return
      }

      if (this.twilioSId) {
        const body = new URLSearchParams()
        body.append('key', cloudApiIframeKey)
        body.append('smsCode', this.smsCode)
        body.append('twilioSId', this.twilioSId)

        try {
          this.$store.commit('showLoader', true)
          const resp = await fetch(cloudApiBaseUrl + '/iframe/verifySms', {
            credentials: 'include',
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: body
          })
          const respText = await resp.text()
          if (respText === 'VALID') {
            this.smsCode = ''
            this.$modal.hide('sms')
            await this.$refs.personalDataSlide.rateProperty()
          } else {
            this.smsCode = ''
            this.$modal.hide('sms')
            this.$modal.show('smsInvalid')
          }
        } catch (e) {
          console.error(e)
        } finally {
          this.$store.commit('showLoader', false)
        }
      }
    },

    onClickSmsInvalid () {
      this.$modal.hide('smsInvalid')
      this.$modal.show('sms')
    },

    async trackStep (step) {
      await analytics.trackStep(step)
    },

    async trackInteraction (type, slideType, data) {
      await analytics.trackInteraction(type, slideType, data)
    }
  },

  watch: {
    currentSlide: {
      immediate: true,
      async handler (newValue, oldValue) {
        if (newValue === oldValue) return

        try {
          // Setze die Flags VOR der Navigation
          this.isNavigating = true
          this.$store.commit('setTransitioning', true)
          console.log(`Starting slide transition from ${oldValue} to ${newValue}`)

          // Tracking für den verlassenen Slide
          if (oldValue !== undefined) {
            const oldSlideWrapper = document.querySelector('.swiper-slide-active')
            const oldSlide = oldSlideWrapper?.querySelector('.prt-slide [data-step-name]')

            if (oldSlide?.dataset?.stepName) {
              await this.trackInteraction(InteractionTypes.SLIDE_EXIT, oldSlide.dataset.stepName, {
                action: 'navigation',
                timestamp: Date.now(),
                nextSlide: newValue
              })
            }
          }

          await this.$nextTick()

          if (this.$refs.prtSwiper?.swiper) {
            const swiper = this.$refs.prtSwiper.swiper

            // Update vor Navigation
            swiper.update()

            // Navigation durchführen
            console.log(`Swiper navigating to slide ${newValue}`)
            await swiper.slideTo(newValue, 300)

            // Warten auf Animation
            await new Promise(resolve => setTimeout(resolve, 350))

            // Update nach Navigation
            swiper.update()
            this.safeIframeResize()

            // Tracking für den neuen Slide
            const activeSlide = document.querySelector('.swiper-slide-active .prt-slide [data-step-name]')
            if (activeSlide?.dataset?.stepName) {
              await this.trackInteraction(InteractionTypes.SLIDE_ENTER, activeSlide.dataset.stepName, {
                timestamp: Date.now(),
                previousSlide: oldValue
              })
            }

            console.log(`Slide transition to ${newValue} completed`)
          }
        } catch (error) {
          console.error('Error during slide transition:', error)
        } finally {
          // Reset der Flags NACH der Navigation
          this.isNavigating = false
          this.$store.commit('setTransitioning', false)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.home-slide {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.prt-swiper {
  height: 100%;
  width: 100%;

  .swiper-container {
    height: auto !important;
  }

  .swiper-wrapper {
    height: auto !important;
    display: flex;
  }

  .swiper-slide {
    height: auto !important;
    flex-shrink: 0;
  }

  .prt-slide-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }
}

// Progress bar
.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: 10;

  .swiper-pagination-progressbar-fill {
    background: var(--secondary-color);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform-origin: left top;
  }
}

// Specific hide for MapsSlide and PersonalDataSlide
.swiper-slide-active {
  .maps-slide, .personal-data-slide {
    & ~ .swiper-pagination {
      display: none !important;
      visibility: hidden !important;
      opacity: 0 !important;
    }
  }
}

.maps-slide, .personal-data-slide {
  & + .swiper-pagination,
  & ~ .swiper-pagination {
    display: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
  }
}

.swiper-slide-active {
  .maps-slide, .personal-data-slide {
    & ~ .swiper-pagination-progressbar {
      display: none !important;
    }
  }
}

// Fix für iOS
.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0, 0, 0);
}

// Loader
.loader-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

// Base styles
.slide-base {
  min-height: 0;
  height: auto;
}

// Modal styles
.modal-content {
  padding: 20px;

  hr {
    margin: 15px 0;
    border: 0;
    height: 1px;
    background: #ccc;
  }
}

.swiper-slide-active {
  .maps-slide, .personal-data-slide {
    & ~ .swiper-pagination-progressbar {
      display: none !important;
    }
  }
}

.privacy-notice {
  background: rgba(0, 0, 0, 0.05);
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  font-size: 0.9em;
}

.consent-required {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
}
</style>
