export const SlideTypes = {
  HOME: 'home_slide',
  REASON: 'reason_slide',
  REASON_WHEN: 'reason_when_slide',
  MAPS: 'maps_slide',

  // Apartment Slides
  LIVING_AREA: 'living_area_slide',
  FLOOR: 'floor_slide',
  ROOM_COUNT: 'room_count_slide',
  CONSTRUCTION_YEAR: 'construction_year_slide',

  // House Slides
  HOUSE_TYPE: 'house_type_slide',
  SITE_AREA: 'site_area_slide',
  FLOOR_COUNT: 'floor_count_slide',

  // Land Slides
  LAND_AREA: 'land_area_slide',
  DEVELOPED_PROPERTY: 'developed_property_slide',
  CONSTRUCTION_POSSIBILITIES: 'construction_possibilities_slide',
  SITE_LAYOUT: 'site_layout_slide',

  // Business Slides
  BUSINESS_TYPE: 'business_type_slide',
  BUSINESS_IS_RENT: 'business_is_rent_slide',

  // Rent Slides
  RENT_TYPE: 'rent_type_slide',
  RENT_CATEGORY: 'rent_category_slide',

  // General Slides
  EMAIL: 'email_slide',
  PERSONAL_DATA: 'personal_data_slide',
  EXTRA: 'extra_slide',
  RESULTS: 'results_slide',
  RESULTS_DEACTIVATED: 'results_deactivated_slide'
}

export const InteractionTypes = {
  SLIDE_ENTER: 'SLIDE_ENTER',
  SLIDE_EXIT: 'SLIDE_EXIT',
  FIELD_FOCUS: 'field_focus',
  FIELD_BLUR: 'field_blur',
  AUTOCOMPLETE_USED: 'autocomplete_used',
  MANUAL_INPUT: 'manual_input',
  CORRECTION: 'correction',
  VALIDATION_SUCCESS: 'validation_success',
  VALIDATION_ERROR: 'validation_error',
  BUTTON_CLICK: 'button_click',
  VALUE_CHANGE: 'value_change',
  FORM_SUBMIT: 'form_submit',
  MAP_INTERACTION: 'map_interaction',
  DROPDOWN_SELECT: 'dropdown_select'
}

export const QualityMetrics = {
  INTERACTION_TIME: 'interaction_time', // Zeit pro Interaktion
  INPUT_CONSISTENCY: 'input_consistency', // Konstanz der Eingaben
  VALIDATION_RATE: 'validation_rate', // Erfolgsrate bei Validierungen
  AUTOCOMPLETE_USAGE: 'autocomplete_usage', // Nutzung von Autocomplete
  CORRECTION_RATE: 'correction_rate', // Häufigkeit von Korrekturen
  NAVIGATION_PATTERN: 'navigation_pattern', // Navigationsmuster
  COMPLETION_SPEED: 'completion_speed', // Geschwindigkeit der Vervollständigung
  INPUT_PRECISION: 'input_precision', // Präzision der Eingaben
  ENGAGEMENT_LEVEL: 'engagement_level' // Engagement-Level
}
