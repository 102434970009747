// ConsentManager.js
import { EventBus } from '@/eventBus'

export class ConsentManager {
  constructor () {
    this.consentState = {
      essential: false,
      statistics: false,
      marketing: false,
      thirdParty: false
    }
    this.consentHistory = []
    this.initialized = false
  }

  async init () {
    if (this.initialized) return

    console.log('Initializing ConsentManager')

    try {
      // Setze initiale Werte
      this.consentState = {
        essential: false,
        statistics: false,
        marketing: false,
        thirdParty: false
      }

      const savedConsent = localStorage.getItem('prt_consent')
      const savedHistory = localStorage.getItem('prt_consent_history')

      if (savedConsent && this.isDsgvoActive()) {
        try {
          const parsedConsent = JSON.parse(savedConsent)
          // Validiere und setze die gespeicherten Werte
          this.consentState = {
            essential: Boolean(parsedConsent.essential),
            statistics: Boolean(parsedConsent.statistics),
            marketing: Boolean(parsedConsent.marketing),
            thirdParty: Boolean(parsedConsent.thirdParty)
          }
          console.log('Loaded and validated consent:', this.consentState)
        } catch (e) {
          console.error('Error parsing saved consent:', e)
          this.resetToDefault()
        }
      } else {
        console.log('No valid consent found, using default (all false)')
      }

      if (savedHistory) {
        try {
          this.consentHistory = JSON.parse(savedHistory)
          console.log('Loaded consent history:', this.consentHistory)
        } catch (e) {
          console.error('Error parsing consent history:', e)
          this.consentHistory = []
        }
      }

      this.initialized = true
      console.log('ConsentManager initialized with state:', this.consentState)
    } catch (error) {
      console.error('Error initializing ConsentManager:', error)
      this.resetToDefault()
    }
  }

  isDsgvoActive () {
    // Prüfe ob DSGVO aktiv ist (z.B. durch Settings oder andere Konfiguration)
    return this.$commons?.getSafe(() => this.$prt.settings.dsgvo.active) || false
  }

  // Neue Methode zum Zurücksetzen
  resetToDefault () {
    this.consentState = {
      essential: false,
      statistics: false,
      marketing: false,
      thirdParty: false
    }
    this.consentHistory = []
    localStorage.removeItem('prt_consent')
    localStorage.removeItem('prt_consent_history')
  }

  async updateConsent (newState) {
    console.log('ConsentManager - Updating consent from:', this.consentState, 'to:', newState)

    // Explizite Boolean-Konvertierung und Validierung
    const cleanState = {
      essential: Boolean(newState?.essential),
      statistics: Boolean(newState?.statistics),
      marketing: Boolean(newState?.marketing),
      thirdParty: Boolean(newState?.thirdParty)
    }

    console.log('Clean consent state:', cleanState)

    // Überprüfe, ob sich der State tatsächlich ändert
    const stateChanged = Object.keys(cleanState).some(
      key => this.consentState[key] !== cleanState[key]
    )

    if (!stateChanged) {
      console.log('No consent state change detected')
      return this.consentState
    }

    const oldState = { ...this.consentState }
    this.consentState = { ...cleanState } // Explizites Kopieren

    // Speichere in localStorage
    try {
      const stateToSave = JSON.stringify({
        essential: this.consentState.essential,
        statistics: this.consentState.statistics,
        marketing: this.consentState.marketing,
        thirdParty: this.consentState.thirdParty
      })
      localStorage.setItem('prt_consent', stateToSave)
      console.log('Consent saved to localStorage:', this.consentState)
    } catch (e) {
      console.error('Error saving consent to localStorage:', e)
    }

    // Füge Änderung zur History hinzu
    this.addToHistory('updated', {
      from: oldState,
      to: { ...this.consentState }
    })

    // Verzögerung für Event-Handling
    await new Promise(resolve => setTimeout(resolve, 50))

    // Informiere andere Komponenten
    EventBus.$emit('consent-updated', { ...this.consentState })
    console.log('Consent update completed, new state:', this.consentState)

    return { ...this.consentState }
  }

  hasConsent (type) {
    if (!this.initialized) {
      console.warn('ConsentManager not initialized')
      return false
    }

    const hasConsent = this.consentState[type] === true
    console.log(`Checking consent for ${type}:`, hasConsent)
    return hasConsent
  }

  hasAllConsent (types) {
    if (!this.initialized) return false
    return types.every(type => this.hasConsent(type))
  }

  hasAnyConsent (types) {
    if (!this.initialized) return false
    return types.some(type => this.hasConsent(type))
  }

  addToHistory (action, data) {
    const historyEntry = {
      action,
      data,
      timestamp: new Date().toISOString()
    }
    this.consentHistory.push(historyEntry)

    // Speichere History in localStorage
    localStorage.setItem('prt_consent_history', JSON.stringify(this.consentHistory))

    // Debug-Log für History-Eintrag
    console.log('Added consent history entry:', historyEntry)
  }

  getConsentStatus () {
    const lastEntry = this.consentHistory[this.consentHistory.length - 1]
    const status = {
      ...this.consentState,
      history: this.consentHistory,
      lastUpdated: lastEntry ? lastEntry.timestamp : null
    }

    // Debug-Log für Status-Abfrage
    console.log('Getting consent status:', status)

    return status
  }

  hasValidConsent () {
    const isValid = this.consentState.essential === true &&
      this.consentHistory.length > 0 &&
      this.consentHistory[this.consentHistory.length - 1]?.timestamp

    // Debug-Log für Validitätsprüfung
    console.log('Checking consent validity:', isValid)

    return isValid
  }
}

export default new ConsentManager()
