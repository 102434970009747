// src/mixins/slideMixin.js
import analytics from '@/stats'

export default {
  data () {
    return {
      slideEnterTime: null
    }
  },

  computed: {
    slideType () {
      const stepName = this.$el?.dataset?.stepName
      if (stepName) {
        const prefix = this.$store.state.collectedData.type
          ? `${this.$store.state.collectedData.type.charAt(0).toUpperCase() +
          this.$store.state.collectedData.type.slice(1)} - `
          : ''
        return `${prefix}${stepName}`
      }
      return this.$options.name
    }
  },

  methods: {
    updateIframeSize () {
      if (window.parentIFrame && typeof window.parentIFrame.resize === 'function') {
        window.parentIFrame.resize()
      }
    },

    calculateTimeSpent () {
      return this.slideEnterTime ? Date.now() - this.slideEnterTime : 0
    },

    // Behalten Sie diese Methoden - sie sind nützlich für spezifische Slide-Interaktionen
    trackSlideComplete (additionalData = {}) {
      const metrics = {
        timeSpent: this.calculateTimeSpent(),
        interactions: analytics.getInteractionTracker().sessionData.interactions
          .filter(i => i.slideType === this.slideType)
      }

      analytics.trackInteraction('SLIDE_COMPLETE', this.slideType, {
        ...additionalData,
        timestamp: Date.now(),
        metrics
      })
    },

    trackSlideExit (action = 'back', additionalData = {}) {
      analytics.trackInteraction('SLIDE_EXIT', this.slideType, {
        action,
        ...additionalData,
        timestamp: Date.now(),
        timeSpent: this.calculateTimeSpent()
      })
    },

    trackSlideEnter (additionalData = {}) {
      this.slideEnterTime = Date.now()
      analytics.trackInteraction('SLIDE_ENTER', this.slideType, {
        ...additionalData,
        timestamp: this.slideEnterTime
      })
    }
  },

  mounted () {
    this.updateIframeSize()
    if (this.$parent.$parent?.$el?.classList?.contains('swiper-slide-active')) {
      this.trackSlideEnter()
    }
  },

  watch: {
    '$parent.$parent.isActive': {
      handler (isActive, wasActive) {
        if (isActive) {
          this.trackSlideEnter()
        } else if (wasActive) {
          this.trackSlideExit('navigation', {
            timeSpent: this.calculateTimeSpent()
          })
        }
      },
      immediate: true
    }
  },

  updated () {
    this.updateIframeSize()
  },

  beforeDestroy () {
    if (this.slideEnterTime) {
      this.trackSlideExit('navigate')
    }
  }
}
