import { cloudApiBaseUrl } from '@/constants'
import { InteractionTypes, SlideTypes } from './constants'
import ConsentManager from '@/ConsentManager'

export class InteractionTracker {
  constructor () {
    this.sessionData = {
      startTime: Date.now(),
      interactions: [],
      qualityScores: {},
      currentSlide: null,
      sessionId: this.generateSessionId(),
      slideTimings: {},
      slideInteractions: {},
      previousValues: {},
      propertyType: null,
      pendingNormalization: [],
      lastInteraction: new Map()
    }

    this.propertyTypeMapping = {
      apartment: 'Wohnung',
      house: 'Haus',
      land: 'Grundstück',
      business: 'Gewerbe',
      rent: 'Miete'
    }

    this.thresholds = {
      // Allgemeine Slides
      'Anfang': { min: 2000, optimal: 5000 },

      // Wohnung
      'Wohnung - Grund': { min: 2000, optimal: 5000 },
      'Wohnung - Zeitpunkt': { min: 2000, optimal: 5000 },
      'Wohnung - Adresse': { min: 5000, optimal: 15000 },
      'Wohnung - Wohnfläche': { min: 3000, optimal: 8000 },
      'Wohnung - Etage': { min: 2000, optimal: 5000 },
      'Wohnung - Zimmer': { min: 2000, optimal: 5000 },
      'Wohnung - Baujahr': { min: 3000, optimal: 8000 },
      'Wohnung - E-Mail': { min: 3000, optimal: 8000 },
      'Wohnung - Persönliche Daten': { min: 5000, optimal: 15000 },

      // Haus
      'Haus - Grund': { min: 2000, optimal: 5000 },
      'Haus - Zeitpunkt': { min: 2000, optimal: 5000 },
      'Haus - Adresse': { min: 5000, optimal: 15000 },
      'Haus - Haustyp': { min: 3000, optimal: 8000 },
      'Haus - Grundfläche': { min: 3000, optimal: 8000 },
      'Haus - Wohnfläche': { min: 3000, optimal: 8000 },
      'Haus - Etagenanzahl': { min: 2000, optimal: 5000 },
      'Haus - Zimmer': { min: 2000, optimal: 5000 },
      'Haus - Baujahr': { min: 3000, optimal: 8000 },
      'Haus - E-Mail': { min: 3000, optimal: 8000 },
      'Haus - Persönliche Daten': { min: 5000, optimal: 15000 },

      // Grundstück
      'Grundstück - Grund': { min: 2000, optimal: 5000 },
      'Grundstück - Zeitpunkt': { min: 2000, optimal: 5000 },
      'Grundstück - Adresse': { min: 5000, optimal: 15000 },
      'Grundstück - Fläche': { min: 3000, optimal: 8000 },
      'Grundstück - Bebauung': { min: 3000, optimal: 8000 },
      'Grundstück - Bebauungsmöglichkeiten': { min: 4000, optimal: 10000 },
      'Grundstück - Ausrichtung': { min: 3000, optimal: 8000 },
      'Grundstück - E-Mail': { min: 3000, optimal: 8000 },
      'Grundstück - Persönliche Daten': { min: 5000, optimal: 15000 },

      // Gewerbe
      'Gewerbe - Grund': { min: 2000, optimal: 5000 },
      'Gewerbe - Zeitpunkt': { min: 2000, optimal: 5000 },
      'Gewerbe - Adresse': { min: 5000, optimal: 15000 },
      'Gewerbe - Art': { min: 3000, optimal: 8000 },
      'Gewerbe - Fläche': { min: 3000, optimal: 8000 },
      'Gewerbe - Grundstück': { min: 3000, optimal: 8000 },
      'Gewerbe - Vermietung': { min: 2000, optimal: 5000 },
      'Gewerbe - Baujahr': { min: 3000, optimal: 8000 },
      'Gewerbe - E-Mail': { min: 3000, optimal: 8000 },
      'Gewerbe - Persönliche Daten': { min: 5000, optimal: 15000 },

      // Miete
      'Miete - Grund': { min: 2000, optimal: 5000 },
      'Miete - Art': { min: 3000, optimal: 8000 },
      'Miete - Adresse': { min: 5000, optimal: 15000 },
      'Miete - Wohnfläche': { min: 3000, optimal: 8000 },
      'Miete - Kategorie': { min: 3000, optimal: 8000 },
      'Miete - Baujahr': { min: 3000, optimal: 8000 },
      'Miete - E-Mail': { min: 3000, optimal: 8000 },
      'Miete - Persönliche Daten': { min: 5000, optimal: 15000 },

      // Extra Steps
      'Extra: Ist alles sauber?': { min: 2000, optimal: 5000 },

      // Default für nicht explizit definierte Slides
      'default': { min: 2000, optimal: 5000 }
    }

    this.normalizeSlideType = this.normalizeSlideType.bind(this)
    this.setCurrentPropertyType = this.setCurrentPropertyType.bind(this)
    this.normalizePendingInteractions = this.normalizePendingInteractions.bind(this)
    this.trackInteraction = this.trackInteraction.bind(this)
  }

  setCurrentPropertyType (type) {
    // Debug: Property Type Änderung
    console.debug('setCurrentPropertyType called:', {
      newType: type,
      oldType: this.sessionData.propertyType,
      pendingNormalization: this.sessionData.pendingNormalization.length
    })

    if (!type || !this.propertyTypeMapping[type]) {
      console.warn('Invalid property type:', type)
      return
    }

    this.sessionData.propertyType = type

    if (this.sessionData.pendingNormalization.length > 0) {
      console.debug('Normalizing pending interactions:', {
        count: this.sessionData.pendingNormalization.length,
        items: this.sessionData.pendingNormalization
      })
      this.normalizePendingInteractions()
    }
  }

  normalizeSlideType (slideType, data) {
    // Wenn der slideType bereits ein Präfix hat oder es "Anfang" ist
    if (slideType === 'Anfang' || slideType.includes(' - ')) {
      return slideType
    }

    // Hole den property type aus dem Store oder den sessionData
    const propertyType = this.sessionData.propertyType ||
      data?.collectedData?.type ||
      data?.sessionData?.collectedData?.type

    if (!propertyType || !this.propertyTypeMapping[propertyType]) {
      if (!this.sessionData.pendingNormalization.includes(slideType)) {
        this.sessionData.pendingNormalization.push(slideType)
        console.debug('Added to pending normalization:', slideType)
      }
      return slideType
    }

    const normalizedType = `${this.propertyTypeMapping[propertyType]} - ${slideType}`
    console.debug('Normalized slide type:', {
      from: slideType,
      to: normalizedType,
      propertyType
    })
    return normalizedType
  }
  normalizePendingInteractions () {
    const propertyType = this.sessionData.propertyType
    if (!propertyType) return

    this.sessionData.interactions = this.sessionData.interactions.map(interaction => {
      if (!interaction.slideType.includes(' - ')) {
        const normalizedType = `${this.propertyTypeMapping[propertyType]} - ${interaction.slideType}`
        console.debug('Normalizing stored interaction:', {
          from: interaction.slideType,
          to: normalizedType
        })

        const oldCount = this.sessionData.slideInteractions[interaction.slideType] || 0
        delete this.sessionData.slideInteractions[interaction.slideType]
        this.sessionData.slideInteractions[normalizedType] = oldCount

        return {
          ...interaction,
          slideType: normalizedType
        }
      }
      return interaction
    })

    if (ConsentManager.hasConsent('statistics')) {
      this.sessionData.interactions.forEach(interaction => {
        this.processInteraction(interaction).catch(console.error)
      })
    }

    this.sessionData.pendingNormalization = []
  }

  // Neue Methode zur Kategorisierung von Interaktionstypen
  getConsentTypeForInteraction (type) {
    // Essential Interactions
    const essentialInteractions = [
      'SLIDE_ENTER',
      'SLIDE_EXIT',
      'VALIDATION_SUCCESS',
      'VALIDATION_ERROR'
    ]

    // Statistic Interactions
    const statisticInteractions = [
      'FIELD_FOCUS',
      'FIELD_BLUR',
      'INPUT_CHANGE',
      'BUTTON_CLICK'
    ]

    // Marketing Interactions
    const marketingInteractions = [
      'FORM_SUBMIT',
      'AUTOCOMPLETE_USED'
    ]

    if (essentialInteractions.includes(type)) return 'essential'
    if (statisticInteractions.includes(type)) return 'statistics'
    if (marketingInteractions.includes(type)) return 'marketing'
    return 'statistics' // Default to statistics for unknown types
  }

  generateSessionId () {
    return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`
  }

  getSlideInteractionCount (slideType) {
    return this.sessionData.slideInteractions[slideType] || 0
  }

  getPreviousValue (slideType) {
    return this.sessionData.previousValues[slideType]
  }

  trackInteraction (type, slideType, data) {
    // DSGVO-Prüfung als Erstes
    const requiredConsent = this.getConsentTypeForInteraction(type)
    if (!ConsentManager.hasConsent(requiredConsent)) {
      console.debug(`Skipping interaction tracking (no ${requiredConsent} consent):`, type)
      return
    }

    const timestamp = Date.now()
    const normalizedSlideType = this.normalizeSlideType(slideType, data)

    // Debounce-Logik: Verhindere zu schnelle aufeinanderfolgende Events
    const lastInteraction = this.sessionData.lastInteraction.get(normalizedSlideType)
    if (lastInteraction) {
      const timeSinceLastInteraction = timestamp - lastInteraction.timestamp
      if (timeSinceLastInteraction < 500) { // 500ms Debounce
        console.debug('Debounced interaction:', {
          type,
          slideType: normalizedSlideType,
          timeSinceLastInteraction
        })
        return
      }
    }

    // Für SLIDE_ENTER: Reset des Zählers
    if (type === 'SLIDE_ENTER') {
      this.sessionData.slideInteractions[normalizedSlideType] = 0
    }

    const interactionCount = this.sessionData.slideInteractions[normalizedSlideType] || 0

    const interaction = {
      type,
      slideType: normalizedSlideType,
      timestamp,
      sessionId: this.sessionData.sessionId,
      data: {
        ...data,
        timestamp,
        userAgent: window.navigator.userAgent,
        screenSize: `${window.innerWidth}x${window.innerHeight}`,
        interactionCount
      },
      qualityIndicators: {
        timeSpent: type === 'SLIDE_ENTER' ? 0 : this.calculateTimeSpent(normalizedSlideType),
        interactionQuality: type === 'SLIDE_ENTER' ? 0.5 : 0.3,
        completionRate: this.calculateCompletionRate(normalizedSlideType),
        validationSuccess: this.calculateValidationSuccess(normalizedSlideType)
      }
    }

    // Speichere diese Interaktion als letzte für diesen Slide-Type
    this.sessionData.lastInteraction.set(normalizedSlideType, {
      timestamp,
      type
    })

    // Speichere die Interaktion
    this.sessionData.interactions.push(interaction)

    // Erhöhe den Zähler NUR wenn es kein SLIDE_ENTER ist
    if (type !== 'SLIDE_ENTER') {
      this.sessionData.slideInteractions[normalizedSlideType] = interactionCount + 1
    }

    // Sende die Interaktion nur wenn Statistics-Consent vorhanden
    if (ConsentManager.hasConsent('statistics')) {
      this.processInteraction(interaction)
        .catch(console.error)
    }

    this.updateQualityScore(interaction)

    // Debug-Logging
    console.debug('Tracked interaction:', {
      type,
      slideType: normalizedSlideType,
      interactionCount,
      timestamp,
      timeSpent: interaction.qualityIndicators.timeSpent,
      consentType: requiredConsent
    })
  }

  calculateTimeSpent (slideType) {
    const interactions = this.sessionData.interactions
      .filter(i => i.slideType === slideType)
      .sort((a, b) => a.timestamp - b.timestamp)

    const lastEnter = interactions.findLast(i => i.type === 'SLIDE_ENTER')

    if (!lastEnter) {
      return 0 // Für den ersten SLIDE_ENTER
    }

    return Date.now() - lastEnter.timestamp
  }

  calculateQualityIndicators (type, slideType, data) {
    return {
      timeSpent: data.timeSpent || this.calculateTimeSpent(slideType),
      interactionQuality: this.calculateInteractionQuality(type, data),
      completionRate: this.calculateCompletionRate(slideType),
      validationSuccess: this.calculateValidationSuccess(slideType)
    }
  }

  calculateTypingConfidence (data) {
    if (!data.focusDuration || data.corrections === undefined) return 0.5

    // Berechne Konfidenz basierend auf:
    // 1. Fokuszeit im Verhältnis zur E-Mail-Länge
    const charsPerSecond = (data.value?.length || 0) / (data.focusDuration / 1000)
    const speedConfidence = charsPerSecond > 0.5 && charsPerSecond < 5 ? 0.3 : 0

    // 2. Korrekturen im Verhältnis zur Länge
    const correctionRatio = (data.value?.length || 1) / (data.corrections + 1)
    const correctionConfidence = correctionRatio > 5 ? 0.4 : 0.2

    // 3. Interaktionsmuster
    const interactionConfidence = data.interactionCount > 0 && data.interactionCount < 8 ? 0.3 : 0

    return speedConfidence + correctionConfidence + interactionConfidence
  }

  calculateEmailInteractionQuality (data) {
    let score = 0.5 // Basis-Score

    // Fokuszeit-Analyse
    if (data.focusDuration) {
      if (data.focusDuration < 500) {
        score -= 0.2 // Zu schnell, könnte automatisiert sein
      } else if (data.focusDuration > 10000) {
        score -= 0.1 // Sehr langsam, könnte unsicher sein
      } else {
        score += 0.2 // Optimale Zeitspanne
      }
    }

    // Korrektur-Analyse
    if (data.corrections !== undefined) {
      if (data.corrections === 0) {
        score += 0.2 // Perfekte Eingabe
      } else if (data.corrections <= 2) {
        score += 0.1 // Normale Korrekturen
      } else {
        score -= 0.1 * Math.min(3, Math.floor(data.corrections / 2)) // Abzug für viele Korrekturen
      }
    }

    // Interaktionszähler
    if (data.interactionCount !== undefined) {
      if (data.interactionCount > 0 && data.interactionCount < 10) {
        score += 0.1 // Normale Interaktionsmenge
      } else if (data.interactionCount >= 10) {
        score -= 0.1 // Viele Interaktionen könnten auf Probleme hinweisen
      }
    }

    return Math.max(0, Math.min(1, score)) // Score zwischen 0 und 1 begrenzen
  }

  calculateTypingNaturalness (data) {
    if (!data.timestamps || data.timestamps.length < 2) return 0.5

    const intervals = []
    for (let i = 1; i < data.timestamps.length; i++) {
      intervals.push(data.timestamps[i] - data.timestamps[i - 1])
    }

    // Berechne Varianzkoeffizient der Tippgeschwindigkeit
    const mean = intervals.reduce((a, b) => a + b, 0) / intervals.length
    const variance = intervals.reduce((a, b) => a + Math.pow(b - mean, 2), 0) / intervals.length
    const stdDev = Math.sqrt(variance)
    const variationCoeff = stdDev / mean

    // Natürliches Tippverhalten hat meist einen Variationskoeffizienten zwischen 0.2 und 0.6
    if (variationCoeff < 0.1) return 0.2 // Zu gleichmäßig, möglicherweise automatisiert
    if (variationCoeff > 1.0) return 0.4 // Zu unregelmäßig
    if (variationCoeff >= 0.2 && variationCoeff <= 0.6) return 1.0
    return 0.7
  }

  calculateInteractionQuality (type, data) {
    const qualityMap = {
      'SLIDE_ENTER': () => {
        // Für den ersten Enter eines Slides
        const isFirstEnter = this.getSlideInteractionCount(data.slideType) === 0
        return isFirstEnter ? 0.5 : this.calculateExitQuality(data)
      },
      'SLIDE_EXIT': () => this.calculateExitQuality(data),
      'default': () => 0.5
    }

    const calculator = qualityMap[type] || qualityMap.default
    return calculator()
  }

  // Debug-Hilfsmethode
  logInteractionState (slideType) {
    console.debug('Current interaction state:', {
      slideType,
      interactionCount: this.getSlideInteractionCount(slideType),
      interactions: this.sessionData.interactions
        .filter(i => i.slideType === slideType)
        .map(i => ({
          type: i.type,
          timestamp: i.timestamp,
          timeSpent: i.qualityIndicators.timeSpent
        }))
    })
  }

  calculateExitQuality (data) {
    const timeSpent = this.calculateTimeSpent(data.slideType)
    const { min, optimal } = this.thresholds[data.slideType] || this.thresholds.default

    if (timeSpent < min) return 0.3 // Zu schnell
    if (timeSpent < optimal) return 0.7 // Okay
    if (timeSpent < optimal * 2) return 1.0 // Optimal
    return 0.8 // Länger als optimal, aber noch gut
  }

  calculateBlurQuality (data) {
    const minExpectedDuration = 1000 // 1 Sekunde
    const maxExpectedDuration = 10000 // 10 Sekunden

    if (!data.focusDuration) return 0.5

    if (data.focusDuration < minExpectedDuration) return 0.3
    if (data.focusDuration > maxExpectedDuration) return 0.7

    return 1.0
  }

  calculateFinalSlideMetrics (slideType) {
    const interactions = this.sessionData.interactions
      .filter(i => i.slideType === slideType)

    return {
      totalTime: this.calculateTimeSpent(slideType),
      interactionCount: interactions.length,
      autoFillCount: interactions
        .filter(i => i.data?.typingMetrics?.isAutoFilled).length,
      validationAttempts: interactions
        .filter(i => i.type.includes('VALIDATION')).length,
      averageQuality: interactions
        .reduce((sum, i) => sum + (i.qualityIndicators?.interactionQuality || 0), 0) /
        interactions.length
    }
  }

  calculateCorrectionRate (data) {
    if (!data.corrections) return 1.0

    const correctionRatio = data.corrections / data.value.length
    if (correctionRatio > 0.5) return 0.3 // Viele Korrekturen
    if (correctionRatio > 0.2) return 0.7 // Moderate Korrekturen
    return 1.0 // Wenige oder keine Korrekturen
  }

  calculateInputQuality (data) {
    if (!data.input) return 0
    const consistencyScore = this.calculateInputConsistency(data.input)
    const speedScore = this.calculateInputSpeed(data.duration)
    return (consistencyScore + speedScore) / 2
  }

  calculateInputConsistency (input) {
    const typingPattern = this.analyzeTypingPattern(input)
    return typingPattern.consistency
  }

  analyzeTypingPattern (input) {
    const patterns = {
      consistency: 0,
      naturalPauses: 0,
      corrections: 0
    }

    if (input.timestamps && input.timestamps.length > 1) {
      let pauseCount = 0

      for (let i = 1; i < input.timestamps.length; i++) {
        const timeDiff = input.timestamps[i] - input.timestamps[i - 1]

        if (timeDiff >= 300 && timeDiff <= 1500) {
          pauseCount++
        }
      }

      patterns.consistency = pauseCount / (input.timestamps.length - 1)
      patterns.naturalPauses = pauseCount
    }

    return patterns
  }

  calculateInputSpeed (duration) {
    if (!duration) return 0.5
    const optimalSpeedRange = { min: 100, max: 300 }
    const actualSpeed = duration

    if (actualSpeed < optimalSpeedRange.min) return 0.3
    if (actualSpeed > optimalSpeedRange.max) return 0.7
    return 1.0
  }

  calculateCorrectionImpact (data) {
    const correctionCount = this.correctionCounts[data.slideType] || 0
    if (correctionCount <= 2) return 1.0
    if (correctionCount <= 4) return 0.8
    return 0.6
  }

  calculateAddressPrecision (data) {
    let precision = 0

    if (data.streetNumber) precision += 0.4
    if (data.postalCode && data.postalCode.length === 5) precision += 0.3
    if (data.locality) precision += 0.3

    return precision
  }

  calculateLocationQuality (data) {
    let quality = 0

    if (data.street && data.houseNumber && data.postalCode && data.city) {
      quality += 0.4
    }

    if (data.postalCode && /^\d{5}$/.test(data.postalCode)) {
      quality += 0.2
    }

    if (data.houseNumber && /^\d+[a-zA-Z]?$/.test(data.houseNumber)) {
      quality += 0.2
    }

    if (data.geocodingSuccess) {
      quality += 0.2
    }

    return quality
  }

  calculateAreaReasonability (data) {
    if (!data.value) return 0.5

    const value = parseFloat(data.value)
    if (isNaN(value)) return 0

    const isHouse = data.slideType.startsWith('Haus')

    if (isHouse) {
      if (value < 30) return 0.2
      if (value > 1000) return 0.3
      if (value >= 80 && value <= 300) return 1.0
      return 0.7
    } else {
      if (value < 20) return 0.2
      if (value > 500) return 0.3
      if (value >= 40 && value <= 200) return 1.0
      return 0.7
    }
  }

  calculateInputPrecision (data) {
    if (!data.value || !data.timestamps) return 0.5

    const inputDuration = data.timestamps[data.timestamps.length - 1] - data.timestamps[0]
    const charactersTyped = data.value.toString().length
    const averageTimePerChar = inputDuration / charactersTyped

    if (averageTimePerChar < 50) return 0.2
    if (averageTimePerChar > 1000) return 0.7
    if (averageTimePerChar >= 100 && averageTimePerChar <= 300) return 1.0
    return 0.8
  }

  calculateEmailQuality (data) {
    if (!data.value) return 0

    let quality = 0
    const email = data.value.toLowerCase()

    // Basis E-Mail Validierung
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      quality += 0.3
    }

    // Domänen-Analyse
    const domain = email.split('@')[1]

    // Geschäftliche Domain (nicht private Mail-Provider)
    const commonProviders = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com', 'web.de', 'gmx.de', 'gmx.net']
    if (!commonProviders.includes(domain)) {
      quality += 0.3 // Höhere Qualität für Business-Domains
    }

    // Eingabequalität
    if (data.focusDuration) {
      // Natürliche Eingabezeit (zwischen 1 und 5 Sekunden)
      if (data.focusDuration >= 1000 && data.focusDuration <= 5000) {
        quality += 0.2
      }
    }

    // Korrekturen analysieren
    if (data.corrections !== undefined) {
      // Wenige Korrekturen (0-2) deuten auf Sicherheit hin
      if (data.corrections <= 2) {
        quality += 0.2
      } else if (data.corrections <= 4) {
        quality += 0.1
      }
    }

    return Math.min(1, quality) // Maximaler Qualitätsscore ist 1.0
  }

  calculateCompletionRate (slideType) {
    return 1.0
  }

  calculateValidationSuccess (slideType) {
    // Sicherheitscheck für this.validationAttempts
    this.validationAttempts = this.validationAttempts || {}

    // Debug-Logging
    console.debug('calculateValidationSuccess called with:', {
      slideType,
      validationAttemptsAvailable: !!this.validationAttempts,
      currentAttempts: this.validationAttempts[slideType]
    })

    // Hole die Validierungsversuche mit Fallback
    const attempts = this.validationAttempts[slideType] || { success: 0, total: 0 }

    // Immer einen gültigen Wert zurückgeben
    if (!attempts.total) return 1.0

    // Berechne und begrenze den Wert zwischen 0 und 1
    const rate = attempts.success / attempts.total
    return Math.max(0, Math.min(1, rate))
  }

  async processInteraction (interaction) {
    try {
      const response = await fetch(`${cloudApiBaseUrl}/iframe/trackInteraction`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Session-ID': this.sessionData.sessionId
        },
        body: JSON.stringify(interaction)
      })

      if (!response.ok) {
        throw new Error(`Failed to track interaction: ${response.statusText}`)
      }
    } catch (error) {
      console.error('Error tracking interaction:', error)
      throw error // Re-throw für weitere Behandlung
    }
  }

  updateQualityScore (interaction) {
    const currentScore = this.sessionData.qualityScores[interaction.slideType] || 0
    const interactionImpact = this.calculateInteractionImpact(interaction)

    this.sessionData.qualityScores[interaction.slideType] =
      (currentScore + interactionImpact) / 2
  }

  calculateInteractionImpact (interaction) {
    const baseImpact = interaction.qualityIndicators.interactionQuality || 0.5
    const timeImpact = this.calculateTimeImpact(interaction)
    const accuracyImpact = this.calculateAccuracyImpact(interaction)

    return (baseImpact + timeImpact + accuracyImpact) / 3
  }

  calculateTimeImpact (interaction) {
    const timeSpent = interaction.qualityIndicators.timeSpent
    const expectedTime = this.getExpectedTime(interaction.slideType)

    if (timeSpent < expectedTime * 0.3) return 0.3
    if (timeSpent > expectedTime * 2) return 0.7
    return 1.0
  }

  calculateAccuracyImpact (interaction) {
    switch (interaction.type) {
      case InteractionTypes.VALIDATION_SUCCESS:
        return 1.0
      case InteractionTypes.VALIDATION_ERROR:
        return 0.3
      case InteractionTypes.CORRECTION:
        return 0.8
      default:
        return 0.5
    }
  }

  getExpectedTime (slideType) {
    const baseExpectations = {
      [SlideTypes.MAPS]: 45000,
      [SlideTypes.PERSONAL_DATA]: 60000,
      [SlideTypes.REASON]: 20000,
      [SlideTypes.EMAIL]: 30000
    }

    return baseExpectations[slideType] || 30000
  }

  getSessionSummary () {
    // Nur Basic-Informationen ohne Consent
    if (!ConsentManager.hasConsent('statistics')) {
      return {
        sessionId: this.sessionData.sessionId,
        duration: Date.now() - this.sessionData.startTime,
        interactionCount: 0,
        qualityScores: {},
        overallQuality: 0
      }
    }

    // Vollständige Informationen mit Consent
    return {
      sessionId: this.sessionData.sessionId,
      duration: Date.now() - this.sessionData.startTime,
      interactionCount: this.sessionData.interactions.length,
      qualityScores: this.sessionData.qualityScores,
      overallQuality: this.calculateOverallQuality()
    }
  }

  calculateOverallQuality () {
    const scores = Object.values(this.sessionData.qualityScores)
    if (scores.length === 0) return 0

    return scores.reduce((sum, score) => sum + score, 0) / scores.length
  }
}
