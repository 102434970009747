<template>
  <div v-if="showBanner" class="dsgvo-consent-banner" :class="{ 'minimized': isMinimized }">
    <div class="consent-content">
      <div v-if="!isMinimized" class="consent-header">
        <h3>{{ $prt.getDsgvoSettings('consentTitle', 'Datenschutzhinweis') }}</h3>
        <button class="minimize-btn" @click="minimizeBanner">
          <span>−</span>
        </button>
      </div>

      <div v-if="!isMinimized" class="consent-body">
        <p>{{ $prt.getDsgvoSettings('consentText', 'Für die Erstellung Ihrer persönlichen Immobilienbewertung benötigen wir einige Angaben zu Ihrer Immobilie. Diese werden verschlüsselt übertragen und gemäß unserer Datenschutzbestimmungen verarbeitet.') }}</p>

        <div class="consent-options">
          <div class="consent-option">
            <!-- Änderung hier: readonly statt disabled -->
            <input
              type="checkbox"
              id="essential"
              v-model="localConsent.essential"
              readonly
              @click="handleEssentialClick"
            >
            <label for="essential">Erforderlich</label>
            <span class="info-text">Technisch notwendig für die Funktionalität</span>
          </div>

          <div class="consent-option">
            <input
              type="checkbox"
              id="statistics"
              v-model="localConsent.statistics"
              :disabled="!localConsent.essential"
            >
            <label for="statistics">Statistiken</label>
            <span class="info-text">Hilft uns den Service zu verbessern</span>
          </div>

          <div class="consent-option">
            <input
              type="checkbox"
              id="marketing"
              v-model="localConsent.marketing"
              :disabled="!localConsent.essential"
            >
            <label for="marketing">Marketing</label>
            <span class="info-text">Personalisierte Angebote</span>
          </div>
        </div>

        <div class="consent-actions">
          <button class="accept-all" @click="acceptAll">
            Alle akzeptieren
          </button>
          <button class="accept-selection" @click="acceptSelection">
            Auswahl bestätigen
          </button>
          <button class="accept-essential" @click="acceptEssential">
            Nur erforderliche
          </button>
        </div>
      </div>

      <div v-else class="minimized-content">
        <button class="maximize-btn" @click="isMinimized = false">
          Datenschutz-Einstellungen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ConsentManager from '@/ConsentManager'

export default {
  name: 'DsgvoConsentBanner',

  data () {
    return {
      showBanner: true,
      isMinimized: false,
      localConsent: {
        essential: true,
        statistics: false,
        marketing: false,
        thirdParty: false
      },
      initialLoad: true
    }
  },

  async created () {
    console.log('DsgvoConsentBanner created')
    await ConsentManager.init()
    console.log('ConsentManager initialized')

    // Lade gespeicherten Consent-Status
    const savedConsent = ConsentManager.getConsentStatus()
    console.log('Consent Status:', savedConsent)

    // Prüfe ob bereits eine vollständige Einwilligung vorliegt
    if (savedConsent.history && savedConsent.history.length > 0) {
      this.localConsent = { ...savedConsent }
      this.showBanner = false
    } else {
      // Wenn keine Einwilligung vorliegt, Banner anzeigen
      this.showBanner = true
      // Essential standardmäßig angehakt aber noch nicht bestätigt
      this.localConsent.essential = true
    }

    this.initialLoad = false
    console.log('Show Banner:', this.showBanner)
  },

  methods: {
    handleEssentialClick (event) {
      // Verhindert das Ändern des essential Checkboxstatus durch Klick
      event.preventDefault()
    },

    minimizeBanner () {
      this.isMinimized = true
    },

    acceptAll () {
      this.localConsent = {
        essential: true,
        statistics: true,
        marketing: true,
        thirdParty: true
      }
      this.saveConsent()
    },

    acceptSelection () {
      // Stelle sicher, dass essential true ist
      this.localConsent.essential = true
      this.saveConsent()
    },

    acceptEssential () {
      this.localConsent = {
        essential: true,
        statistics: false,
        marketing: false,
        thirdParty: false
      }
      this.saveConsent()
    },

    async saveConsent () {
      console.log('DsgvoConsentBanner - Saving consent:', this.localConsent)

      try {
        // Consent aktualisieren
        await ConsentManager.updateConsent(this.localConsent)

        // Banner und Modal schließen
        this.showBanner = false
        this.$modal.hide('dsgvoCookie')

        // Warten auf DOM-Updates
        await this.$nextTick()

        // Wenn essential akzeptiert wurde, zum nächsten Slide
        if (this.localConsent.essential) {
          this.$store.commit('nextSlide')
        }

        console.log('DsgvoConsentBanner - Consent saved and modal closed')
      } catch (error) {
        console.error('Error saving consent:', error)
      }
    }
  }
}
</script>

<style scoped>
.dsgvo-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 1rem;
  transition: all 0.3s ease;
}

.dsgvo-consent-banner.minimized {
  padding: 0.5rem;
}

.consent-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.consent-options {
  margin: 1rem 0;
}

.consent-option {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
}

.consent-option label {
  margin: 0 0.5rem;
}

.info-text {
  font-size: 0.8rem;
  color: #666;
  margin-left: 0.5rem;
}

.consent-actions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1rem;
}

button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.accept-all {
  background: #4CAF50;
  color: white;
}

.accept-selection {
  background: #2196F3;
  color: white;
}

.accept-essential {
  background: #9E9E9E;
  color: white;
}

.minimize-btn, .maximize-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
}

.minimized-content {
  display: flex;
  justify-content: flex-end;
}

.consent-option input[type="checkbox"] {
  cursor: pointer;
}

.consent-option input[type="checkbox"]:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.consent-option input[type="checkbox"][readonly] {
  cursor: not-allowed;
  opacity: 1;
}
</style>
