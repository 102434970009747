<template>
  <div id="app" data-iframe-size>
    <component :is="currentComponent" />
  </div>
</template>

<script>
import '@/assets/css/_layout.scss'
import Home from '@/views/Home'
import ChatbotDisplay from '@/components/ChatbotDisplay'
import analytics from '@/stats'
import ConsentManager from '@/ConsentManager'

export default {
  components: { Home, ChatbotDisplay },

  data () {
    return {
      currentComponent: 'Home',
      resizeObserver: null,
      mutationObserver: null,
      resizeInterval: null
    }
  },

  async mounted () {
    // URL Parameter verarbeiten
    const urlParams = new URLSearchParams(window.location.search)
    const view = urlParams.get('view')

    await ConsentManager.init()

    analytics.trackStep('Anfang')

    // Debug Mode aktivieren wenn debug Parameter vorhanden
    const debugMode = urlParams.has('debug')
    let lastHeight = 0
    let resizeCount = 0
    const startTime = Date.now()
    let resizeTimeout = null

    // Component basierend auf view Parameter setzen
    this.currentComponent = view === 'chatbot' ? 'ChatbotDisplay' : 'Home'

    // Initiale Größenanpassung
    this.$nextTick(() => {
      // Entferne data-iframe-size von allen Elementen außer dem Haupt-Container
      document.querySelectorAll('[data-iframe-size]').forEach(el => {
        if (el.id !== 'app') {
          el.removeAttribute('data-iframe-size')
        }
      })

      const appElement = document.getElementById('app')
      if (!appElement) return

      const debugLog = (source, height, mutation = null) => {
        if (!debugMode) return

        const timeSinceStart = Date.now() - startTime
        const heightDiff = height - lastHeight
        resizeCount++

        console.group(`%ciFrame Resize Debug #${resizeCount} (${timeSinceStart}ms)`, 'color: #2196F3; font-weight: bold;')
        console.log('Source:', source)
        console.log('Current Height:', height)
        console.log('Previous Height:', lastHeight)
        console.log('Difference:', heightDiff)

        if (mutation) {
          console.log('Mutation Details:', {
            type: mutation.type,
            target: mutation.target,
            targetHeight: mutation.target.offsetHeight,
            changedAttributes: mutation.attributeName ? [mutation.attributeName] : []
          })
        }

        // Nur den aktiven Slide in der Höhenberechnung berücksichtigen
        const activeSlide = document.querySelector('.swiper-slide-active')
        if (activeSlide) {
          console.log('Active Slide Height:', activeSlide.offsetHeight)
        }

        console.groupEnd()
        lastHeight = height
      }

      const updateSize = () => {
        if (!window.parentIFrame) return

        // Bestehenden Timeout löschen
        if (resizeTimeout) {
          clearTimeout(resizeTimeout)
        }

        // Neuen Timeout setzen
        resizeTimeout = setTimeout(() => {
          requestAnimationFrame(() => {
            try {
              const activeSlide = document.querySelector('.swiper-slide-active')
              const currentHeight = activeSlide ? activeSlide.offsetHeight : appElement.offsetHeight

              // Prüfen ob die Höhe sich wirklich geändert hat
              if (currentHeight !== lastHeight) {
                debugLog('ResizeUpdate', currentHeight)
                window.parentIFrame.resize()
              }
            } catch (error) {
              console.warn('Error updating size:', error)
            }
          })
        }, 100) // Debounce-Zeit von 100ms
      }

      // Initial size update
      updateSize()

      // ResizeObserver für Größenänderungen
      this.resizeObserver = new ResizeObserver(entries => {
        const entry = entries[0]
        if (entry) {
          const activeSlide = document.querySelector('.swiper-slide-active')
          if (activeSlide && entry.target.contains(activeSlide)) {
            debugLog('ResizeObserver', activeSlide.offsetHeight)
            updateSize()
          }
        }
      })

      // Nur den App-Container beobachten
      this.resizeObserver.observe(appElement)

      // MutationObserver für DOM-Änderungen
      this.mutationObserver = new MutationObserver((mutations) => {
        const relevantMutation = mutations.some(mutation => {
          const activeSlide = document.querySelector('.swiper-slide-active')
          return activeSlide && (
            mutation.target === activeSlide ||
            activeSlide.contains(mutation.target)
          )
        })

        if (relevantMutation) {
          const activeSlide = document.querySelector('.swiper-slide-active')
          if (activeSlide) {
            debugLog('MutationObserver', activeSlide.offsetHeight)
            updateSize()
          }
        }
      })

      // DOM-Änderungen überwachen
      this.mutationObserver.observe(appElement, {
        childList: true,
        subtree: true,
        attributes: true,
        characterData: true
      })

      // Event-Listener für Ressourcen
      const loadHandler = () => {
        const activeSlide = document.querySelector('.swiper-slide-active')
        if (activeSlide) {
          debugLog('WindowLoad', activeSlide.offsetHeight)
          updateSize()
        }
      }
      window.addEventListener('load', loadHandler)

      // Cleanup
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('load', loadHandler)
        if (resizeTimeout) {
          clearTimeout(resizeTimeout)
        }
        if (debugMode) {
          console.log(`Total resize count: ${resizeCount}`)
          console.log(`Total time: ${Date.now() - startTime}ms`)
        }
      })
    })
  },

  beforeDestroy () {
    // Cleanup
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
    if (this.mutationObserver) {
      this.mutationObserver.disconnect()
    }
    if (this.resizeInterval) {
      clearInterval(this.resizeInterval)
    }
  },

  watch: {
    currentComponent: {
      handler () {
        this.$nextTick(() => {
          if (window.parentIFrame) {
            window.parentIFrame.resize()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
:root {
  // Color Variables
  --primary-color: #002335;
  --secondary-color: #17b5a0;
  --primary-color-light: #00324b;
  --primary-color-dark: #001824;
  --secondary-color-light: #19c7b0;
  --secondary-color-dark: #14a290;
  --button-prev-color: #fc5c65;
  --button-next-color: #20bf6b;
  --button-finish-color: #20bf6b;
  --primary-font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  --primary-font-color: #2c3e50;
  --slide-max-width: 1000px;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: auto !important;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  font-family: var(--primary-font-family);
  width: 100%;
  min-height: 600px;
  color: var(--primary-font-color);
  display: flex;
  flex-direction: column;
  overflow: auto !important;
}

.router-view {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.slide-content {
  height: auto;
  min-height: 100vh;
  overflow-y: visible !important;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

html, body {
  overflow: visible !important;
  height: auto;
}
</style>
