// PrivacyProxy.js
import ConsentManager from './ConsentManager'

export class PrivacyProxy {
  constructor () {
    this.processors = new Set()
    this.dataCategories = new Map()
    this.initialized = false
    console.log('PrivacyProxy constructed')
  }

  async init (settings) {
    console.log('Initializing PrivacyProxy with settings:', settings)
    if (this.initialized) return

    settings.processors.forEach(processor => {
      this.processors.add({
        ...processor,
        active: processor.role === 'primary'
      })
    })

    // Kategorien mit Consent-Anforderungen definieren
    this.dataCategories.set('essential', {
      fields: ['propertyType', 'location', 'size'],
      required: true,
      purpose: 'Grundlegende Immobilienbewertung',
      consentType: 'essential'
    })

    this.dataCategories.set('extended', {
      fields: ['yearBuilt', 'condition', 'features'],
      required: false,
      purpose: 'Detaillierte Immobilienbewertung',
      consentType: 'statistics'
    })

    this.dataCategories.set('contact', {
      fields: ['email', 'phone', 'name'],
      required: true,
      purpose: 'Kontaktaufnahme',
      consentType: 'marketing'
    })

    this.initialized = true
    console.log('PrivacyProxy initialized')
  }

  // Neue Methode zur Consent-Prüfung für Kategorien
  canProcessCategory (category) {
    const categoryConfig = this.dataCategories.get(category)
    if (!categoryConfig) return false

    return categoryConfig.required || ConsentManager.hasConsent(categoryConfig.consentType)
  }

  validateData (data, category) {
    const categoryConfig = this.dataCategories.get(category)
    if (!categoryConfig) {
      throw new Error(`Unknown data category: ${category}`)
    }

    // Prüfe zuerst Consent
    if (!this.canProcessCategory(category)) {
      throw new Error(`No consent for data category: ${category}`)
    }

    const validatedData = {}
    const missing = []

    categoryConfig.fields.forEach(field => {
      if (data[field]) {
        validatedData[field] = this.sanitizeField(field, data[field])
      } else if (categoryConfig.required) {
        missing.push(field)
      }
    })

    if (missing.length > 0) {
      throw new Error(`Missing required fields for ${category}: ${missing.join(', ')}`)
    }

    return validatedData
  }

  sanitizeField (field, value) {
    if (typeof value === 'string') {
      value = value.trim()
      value = value.replace(/[<>]/g, '')
    }

    switch (field) {
      case 'email':
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          throw new Error('Invalid email format')
        }
        break
      case 'phone':
        value = value.replace(/[^\d+\s-]/g, '')
        break
      case 'size':
        value = parseFloat(value)
        if (isNaN(value) || value <= 0) {
          throw new Error('Invalid size value')
        }
        break
    }

    return value
  }

  async processData (data, category) {
    // Prüfe und validiere Daten unter Berücksichtigung des Consents
    const validatedData = this.validateData(data, category)

    // Pseudonymisierung für nicht-essenzielle Daten
    if (category !== 'essential') {
      validatedData.id = this.generatePseudonym()
    }

    return {
      ...validatedData,
      processed: new Date().toISOString(),
      category
    }
  }

  generatePseudonym () {
    return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`
  }
}

export default new PrivacyProxy()
